import { RouteComponentProps } from "@reach/router";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { updateElementControls } from "../../core/store/elements/actions";
import {
  getElementControls,
  getElementsFromControls,
} from "../../core/store/elements/reducers";
import { updateElementControlsAndMaxCost } from "../../core/store/elements/thunks";
import {
  IElementControls,
  IElementDataFromControls,
} from "../../core/store/elements/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import { FieldCol, FieldCols } from "../FieldRenderers";
import InjuryTracker from "../status/InjuryTracker";
import { Main, Wrapper } from "../Layout";
import Title from "../Title";
import ElementFilter from "../element-controls/ElementFilter";
import Paginator, { paginate } from "../element-controls/Paginator";

type Props = RouteComponentProps;

interface IPropsFromState {
  teamsById: ITeamsById;
  elements: IElementDataFromControls;
  controls: IElementControls;
}

interface IPropsFromDispatch {
  updateControls: (controls: IElementControls) => void;
  updateControlsAndMaxCost: (controls: IElementControls) => void;
}

type IProps = Props & IPropsFromState & IPropsFromDispatch;

const FilterWrap = styled.div`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    width: 40%;
  }
`;

const News = ({
  teamsById,
  elements,
  controls,
  updateControls,
  updateControlsAndMaxCost,
}: IProps) => {
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPage(1);
    updateControls({
      ...controls,
      filter: e.target.value,
    });
  };

  useEffect(() => {
    updateControlsAndMaxCost({
      ...controls,
      filter: "all",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data, totalPages } = paginate(
    elements.data?.filter((element) => element.news !== ""),
    page,
    15
  );

  return (
    <>
      <Wrapper>
        <Main>
          <Box mx={2}>
            <Title>{t("news.title", "Player News")}</Title>
          </Box>

          <form>
            <FilterWrap>
              <FieldCols>
                <FieldCol>
                  <ElementFilter handleFilterChange={handleFilterChange} />
                </FieldCol>
              </FieldCols>
            </FilterWrap>
          </form>
          <InjuryTracker
            showLink={false}
            teamsById={teamsById}
            elements={data}
            borderBottom={false}
          />
          <Paginator totalPages={totalPages} page={page} setPage={setPage} />
        </Main>
      </Wrapper>
    </>
  );
};

export { News as NewsTest };

const mapStateToProps = (state: RootState): IPropsFromState => ({
  teamsById: getTeamsById(state),
  controls: getElementControls(state),
  elements: getElementsFromControls(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  updateControls: (controls) => dispatch(updateElementControls(controls)),
  updateControlsAndMaxCost: (controls) =>
    dispatch(updateElementControlsAndMaxCost(controls)),
});

export default connect(mapStateToProps, mapDispatchToProps)(News);
