import { IEvent } from "../core/store/events/types";
import { FixturesByEvent } from "../core/store/fixtures/types";
import { IElement } from "../core/store/elements/types";

export const getNextElementFixtures = (
  nextEvent: IEvent | null,
  numberOfFixtures: number,
  fixturesByEvent: FixturesByEvent,
  element: IElement
) => {
  const elementFixtures: any[] = [];
  if (nextEvent) {
    for (let i = 0; i < numberOfFixtures; i++) {
      const eventId = nextEvent.id + i;
      if (fixturesByEvent[eventId]) {
        // No chance of a team having more than one fixture
        const fixture = fixturesByEvent[eventId].find(
          (f: any) => f.team_h === element.team || f.team_a === element.team
        );
        if (fixture) {
          elementFixtures[i] = fixture;
        } else {
          elementFixtures[i] = { code: null };
        }
      }
    }
  }
  return elementFixtures;
};
