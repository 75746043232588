import { em } from "polished";
import i18n from "./i18n";

const borderWidths = ["3px"];

const blue = "#2a63d2";
const blueDark = "#1d428a";
const greyBlue = "#c4ced4";
const orange = "#d9731c";
const pink = "#e7358d";
const red = "#c8102e";

// Default theme
const baseTheme = {
  borderWidths,
  breakpoints: [
    em("400px"),
    em("610px"),
    em("700px"),
    em("900px"),
    em("1024px"),
    em("1220px"),
  ],
  colors: {
    blue,
    blueDark,
    blueLight: "#53bde6",
    charcoal: "#333d47",
    elementTypes: {
      1: {
        colors: [blueDark, blue],
      },
      2: {
        colors: [red, pink],
      },
    },
    error: red,
    green: "#06ac0d",
    greyBlue,
    greyLight: "#e6e6e6",
    offBlack: "#262626",
    orange,
    pink,
    primary: blueDark,
    red,
    teams: {
      1610612737: {
        dark: "#cb102e",
        light: "#000",
      },
      1610612738: {
        dark: "#00b348",
        light: "#000",
      },
      1610612739: {
        dark: "#860038",
        light: "#bc945c",
      },
      1610612740: {
        dark: "#0a2240",
        light: "#8c734b",
      },
      1610612741: {
        dark: "#ce1141",
        light: "#000",
      },
      1610612742: {
        dark: "#0064b1",
        light: "#002b5e",
      },
      1610612743: {
        dark: "#0e2240",
        light: "#fec524",
      },
      1610612744: {
        dark: "#1d428a",
        light: "#fdb927",
      },
      1610612745: {
        dark: "#ce1141",
        light: "#000",
      },
      1610612746: {
        dark: "#c8102e",
        light: "#1d42ba",
      },
      1610612747: {
        dark: "#552583",
        light: "#fdb927",
      },
      1610612748: {
        dark: "#000",
        light: "#98002e",
      },
      1610612749: {
        dark: "#00471b",
        light: "#000",
      },
      1610612750: {
        dark: "#0c233f",
        light: "#266092",
      },
      1610612751: {
        dark: "#000",
        light: "#707271",
      },
      1610612752: {
        dark: "#1d428a",
        light: "#f58426",
      },
      1610612753: {
        dark: "#0077c0",
        light: "#000",
      },
      1610612754: {
        dark: "#002d62",
        light: "#fdbb30",
      },
      1610612755: {
        dark: "#006bb6",
        light: "#ed174c",
      },
      1610612756: {
        dark: "#1d116d",
        light: "#e56020",
      },
      1610612757: {
        dark: "#e03a3e",
        light: "#000",
      },
      1610612758: {
        dark: "#5a2d81",
        light: "#000",
      },
      1610612759: {
        dark: "#000",
        light: "#c4ced4",
      },
      1610612760: {
        dark: "#007ac1",
        light: "#ef3824",
      },
      1610612761: {
        dark: "#ce1141",
        light: "#000",
      },
      1610612762: {
        dark: "#000",
        light: "#fff21f",
      },
      1610612763: {
        dark: "#5d76a9",
        light: "#12173f",
      },
      1610612764: {
        dark: "#002b5c",
        light: "#e31837",
      },
      1610612765: {
        dark: "#1d42ba",
        light: "#c8102e",
      },
      1610612766: {
        dark: "#00788c",
        light: "#1d1160",
      },
    },
    tertiary: "#06ac0d",
    turquoise: "#3be9e4",
    secondary: "#1d42bb",
    success: "#24db00",
    yellow: "#eabd0c",
    white: "#ffffff",
  },
  fonts: {
    action: '"Action NBA Web", sans-serif;',
    body: '"Roboto", sans-serif;',
  },
  fontSizes: ["1.3rem", "1.4rem", "1.6rem", "1.8rem", "3rem"],
  radii: ["3px", "5px", "8px"],
  space: [
    "0",
    "0.5rem",
    "1rem",
    "1.5rem",
    "2rem",
    "2.5rem",
    "3rem",
    "3.5rem",
    "4rem",
  ],
  zIndex: {
    clientMenu: 7000,
    dialog: 9000,
    menu: 6000,
    overlay: 8000,
    select: {
      select: 5000,
      wrap: 4000,
    },
  },
};

/* Locale themes with custom css */

// Greece (Language code: EL)
const themeEL = {
  ...baseTheme,
  fonts: {
    action: '"Sofia Sans Extra Condensed", sans-serif;',
    body: '"Roboto", sans-serif;',
  },
  fontSizes: ["1.1rem", "1.1rem", "1.4rem", "1.7rem", "2.5rem"],
};

const themeDefault = {
  ...baseTheme,
};

const i18nThemes: any = {
  en: themeDefault,
  el: themeEL,
  es: themeDefault,
  de: themeDefault,
  fr: themeDefault,
  it: themeDefault,
  he: themeDefault,
  tr: themeDefault,
  pt: themeDefault,
  ar: themeDefault,
};

const theme = i18nThemes[i18n.language];

export default theme;
