import styled, { css } from "styled-components/macro";

const ScorePrefixStyles = css`
  text-transform: uppercase;
  padding-inline-end: ${({ theme }) => theme.space[1]};
  &&& {
    font-weight: 600;
  }
`;

export const WinPrefix = styled.span`
  ${ScorePrefixStyles}
  color: ${({ theme }) => theme.colors.green};
`;

export const LosePrefix = styled.span`
  ${ScorePrefixStyles}
  color: ${({ theme }) => theme.colors.red};
`;

export const DrawPrefix = styled.span`
  ${ScorePrefixStyles}
  color: ${({ theme }) => theme.colors.offBlack};
`;
