import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components/macro";
import { RootState } from "../core/store";
import { getElementTypesById } from "../core/store/element-types/reducers";
import { getElement } from "../core/store/elements/reducers";
import i18n from "../i18n";
import { isRTL } from "../utils/locale";
import Media from "./Media";
import Shirt from "./Shirt";
import TeamCode from "./TeamCode";
import { Ellipsis } from "./Utils";

const MenuButton = styled.button`
  width: 100%;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: black;
  font-family: inherit;
  text-align: left;
  line-height: 1;
  cursor: pointer;
`;

const Name = styled.div`
  :hover {
    text-decoration: underline;
  }
  text-align: start;
`;

const SecondName = styled.span`
  font-weight: 500;
`;

interface IStyledElementType {
  elementTypeId: number;
}

const StyledElementType = styled.p<IStyledElementType>`
  display: inline-block;
  ${isRTL(i18n.language)
    ? css`
        margin: ${(theme) => `0 ${theme.theme.space[2]} 0 0`};
      `
    : css`
        margin: ${(theme) => `0 0 0 ${theme.theme.space[2]}`};
      `}
  color: ${(props) =>
    props.theme.colors.elementTypes[props.elementTypeId].colors[0]};
  font-weight: 300;
  font-style: italic;
  font-size: 1.3rem;
  text-decoration: none;
`;

interface IProps {
  elementId: number;
  renderElementMenu: () => void;
}

const ElementInTable: React.FC<IProps> = ({ elementId, renderElementMenu }) => {
  const { t } = useTranslation();
  const element = useSelector((state: RootState) =>
    getElement(state, elementId)
  );
  const elementTypesById = useSelector(getElementTypesById);

  if (!element) {
    return null;
  }

  const elementType = elementTypesById[element.element_type];

  return (
    <MenuButton onClick={renderElementMenu}>
      <Media img={<Shirt elementId={element.id} width="60px" />} centred={true}>
        <Name>
          <div>
            <Ellipsis>{element.first_name}</Ellipsis>
          </div>
          <div>
            <SecondName>
              <Ellipsis>{element.second_name}</Ellipsis>
            </SecondName>
          </div>
        </Name>
        <div>
          <TeamCode isSmall={true} teamId={element.team} />
          <StyledElementType
            elementTypeId={elementType.id}
            title={elementType.singular_name}
          >
            {elementType.id === 2
              ? t("elementType.frontcourtShort", "{{short}}", {
                  short: elementType.singular_name_short,
                })
              : t("elementType.backcourtShort", "{{short}}", {
                  short: elementType.singular_name_short,
                })}
          </StyledElementType>
        </div>
      </Media>
    </MenuButton>
  );
};

export default ElementInTable;
