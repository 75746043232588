import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { RootState } from "../../core/store";
import { getElementStats } from "../../core/store/element-stats/reducers";
import { IElementStat } from "../../core/store/element-stats/types";
import { getElementTypesById } from "../../core/store/element-types/reducers";
import { IElementTypesById } from "../../core/store/element-types/types";
import { getElementsById } from "../../core/store/elements/reducers";
import {
  IElement,
  IElementEventStats,
  IElementsById,
  IElementsEventDataById,
} from "../../core/store/elements/types";
import { IPickLight } from "../../core/store/entries/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import { integerToDecimal } from "../../utils/decimal";
import ElementDialogButton from "../ElementDialogButton";
import ElementInTable from "../ElementInTable";
import { ElementRow, ElementTable } from "../ElementTable";
import { ScrollTable } from "../Table";
import TeamElementIcon from "./TeamElementIcon";

const EntryEventStatus = styled.th`
  width: 6%;
`;

const EntryEventCpt = styled.th`
  width: 4%;
`;

const EntryEventElement = styled.th`
  width: 32%;
`;

const EntryEventPts = styled.th`
  width: 12%;
`;

interface IEntryEventRowProps {
  chipName: string | null;
  data: IElementEventStats;
  pick: IPickLight;
  element: IElement;
  elementTypesById: IElementTypesById;
  renderElementMenu: (index: number) => void;
  stats: ReadonlyArray<IElementStat>;
  teamsById: ITeamsById;
}

const EntryEventRow: React.FC<IEntryEventRowProps> = ({
  chipName,
  data,
  pick,
  element,
  renderElementMenu,
  stats,
  teamsById,
}) => (
  <ElementRow>
    <td>
      <ElementDialogButton elementId={element.id} variant="list" />
    </td>
    <td>
      <TeamElementIcon pick={pick} chipName={chipName} />
    </td>
    <td>
      <ElementInTable
        renderElementMenu={() => renderElementMenu(pick.element)}
        elementId={element.id}
      />
    </td>
    <td>
      {integerToDecimal(data.total_points * Math.max(pick.multiplier, 1), 10)}
    </td>
    {stats.map((s) => (
      <td key={s.name}>{data[s.name]}</td>
    ))}
  </ElementRow>
);

interface IOwnProps {
  chipName: string | null;
  dataById: IElementsEventDataById | null;
  picks: IPickLight[];
  renderElementMenu: (index: number) => void;
  title: string;
}

interface IPropsFromState {
  elementsById: IElementsById;
  elementTypesById: IElementTypesById;
  stats: ReadonlyArray<IElementStat>;
  teamsById: ITeamsById;
}

type Props = IOwnProps & IPropsFromState;

const EntryEventTable: React.FC<Props> = ({
  dataById,
  elementsById,
  picks,
  stats,
  title,
  ...rest
}) => {
  if (!dataById) {
    return null;
  }
  return (
    <ScrollTable>
      <ElementTable>
        <thead>
          <tr>
            <EntryEventStatus>&nbsp;</EntryEventStatus>
            <EntryEventCpt>&nbsp;</EntryEventCpt>
            <EntryEventElement>{title}</EntryEventElement>
            <EntryEventPts>TP</EntryEventPts>
            {stats.map((s) => (
              <th key={s.name}>
                <abbr title={s.label}>
                  {s.label
                    .split(" ")
                    .map((w) => w[0])
                    .join("")
                    .toUpperCase()}
                </abbr>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {picks.map((p) => (
            <EntryEventRow
              key={p.element}
              pick={p}
              element={elementsById[p.element]}
              stats={stats}
              data={dataById[p.element].stats}
              {...rest}
            />
          ))}
        </tbody>
      </ElementTable>
    </ScrollTable>
  );
};

export { EntryEventTable as EntryEventTableTest };

const mapStateToProps = (state: RootState): IPropsFromState => {
  return {
    elementsById: getElementsById(state),
    elementTypesById: getElementTypesById(state),
    stats: getElementStats(state),
    teamsById: getTeamsById(state),
  };
};

export default connect(mapStateToProps)(EntryEventTable);
