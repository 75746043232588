import { setDefaultOptions } from "date-fns";
import { ar, de, el, enUS, es, fr, he, it, pt, tr } from "date-fns/locale";
import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import moment from "moment";
import "moment/locale/ar";
import "moment/locale/de";
import "moment/locale/el";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/he";
import "moment/locale/it";
import "moment/locale/pt";
import "moment/locale/tr";
import { initReactI18next } from "react-i18next";

const locales = ["de", "en", "es", "fr", "it", "el", "he", "tr", "pt", "ar"];

if (
  process.env.REACT_APP_SENTRY_ENVIRONMENT &&
  process.env.REACT_APP_SENTRY_ENVIRONMENT === "production"
) {
  if (window.location.hostname.substring(0, 2) === "he") {
    window.location.assign("https://nbafantasy.sport5.co.il");
  }
  if (window.location.hostname.substring(0, 2) === "it") {
    window.location.assign("https://nbafantasy.sky.it");
  }
}

const hostnameLocale =
  window.location.hostname.substring(0, 2) === "gr"
    ? "el"
    : window.location.hostname.includes("sport5")
    ? "he"
    : window.location.hostname.includes("sky")
    ? "it"
    : window.location.hostname.substring(0, 2) === "br"
    ? "pt"
    : window.location.hostname.substring(0, 2);
// hack for the GR vs EL greek domain/locale
// and sport5 affiliate in HE

const lng = locales.indexOf(hostnameLocale) >= 0 ? hostnameLocale : "en";
export const dateLocales: Record<string, Locale> = {
  de,
  en: enUS,
  es,
  fr,
  it,
  el,
  he,
  tr,
  pt,
  ar,
};
// Update moment and date-fns locale
i18n.on("languageChanged", function (lng) {
  moment.locale(lng);
  setDefaultOptions({ locale: dateLocales[lng] });
});

i18n
  .use(Backend)
  .use(initReactI18next)

  .init({
    ns: ["common"],
    defaultNS: "common",
    lng,
    fallbackLng: "en", // to stop trying to fetch dev transations
    debug: true,
    interpolation: {
      escapeValue: false, // react already safe from xss as escaped by default
    },
    initImmediate: false, // makes the language code accessible in src/theme.ts (PR #4035)
  });

export default i18n;
