import styled, { css } from "styled-components/macro";
import { Link as RouterLink } from "@reach/router";

export const LinkStyles = css`
  color: ${({ theme }) => `${theme.colors.blueDark}`};
  font-weight: 500;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

export const Hyperlink = styled.a`
  ${LinkStyles}
`;

const Link = styled(RouterLink)`
  ${LinkStyles}
`;

export default Link;
