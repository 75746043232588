import { Link } from "@reach/router";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Box } from "rebass/styled-components";
import styled, { css } from "styled-components/macro";
import { RootState } from "../core/store";
import { getLoginError, getPlayerData } from "../core/store/player/reducers";
import { login } from "../core/store/player/thunks";
import { ILoginData } from "../core/store/player/types";
import Alert from "./Alert";
import Button from "./Button";
import Copy from "./Copy";
import { FieldWrap, InputField } from "./FieldRenderers";
import SubHeading from "./SubHeading";
import { rgba } from "polished";
import { isRTL } from "../utils/locale";
import i18n from "../i18n";

const LoginWrapper = styled.div`
  padding: 0 ${(props) => props.theme.space[4]};
  border-radius: ${(props) => props.theme.radii[2]};
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.offBlack};

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    box-shadow: 1px 2px 10px 2px
      ${({ theme }) => `${rgba(`${theme.colors.offBlack}`, 0.1)}`};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    padding: ${(props) => props.theme.space[4]};
    box-shadow: 1px 2px 10px 2px
      ${({ theme }) => `${rgba(`${theme.colors.offBlack}`, 0.1)}`};
  }
`;

const LoginSection = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    position: relative;
    flex-direction: row;
    gap: 6%;
  }
`;

const PromoMain = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    flex: 55%;
    padding-top: ${(props) => props.theme.space[2]};
  }
`;

const PromoCopy = styled.p``;

const HomeSubHeading = styled(SubHeading)`
  font-family: ${(props) => props.theme.fonts.action};
  text-transform: uppercase;
  color: inherit;
  font-style: italic;
  font-size: 6.5rem;
`;

const Username = styled(FieldWrap)``;

const LoginForgetPassword = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    flex-direction: row;
    align-items: center;
    gap: ${(props) => props.theme.space[3]};
  }
`;

const Password = styled(FieldWrap)``;

const ButtonSeparation = styled.div`
  margin-bottom: ${(props) => props.theme.space[3]};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    display: none;
  }
`;

const LoginButtonWrap = styled.div`
  flex: 1;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    margin: 0;
  }
`;

const CreateAccountButtonWrap = styled.div`
  margin-bottom: ${({ theme }) => theme.space[2]};
`;

const LoginForm = styled.form`
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    flex: 45%;
  }
`;

const LoginLink = styled(Link)`
  display: block;
  margin-bottom: ${({ theme }) => theme.space[2]};
  padding: 1.5rem;
  border-radius: ${(props) => props.theme.radii[1]};
  background-color: ${(props) => props.theme.colors.yellow};
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  color: black;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    position: absolute;
    width: 320px;
    bottom: 0;
    ${isRTL(i18n.language)
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `}
  }
`;

const LoginTitle = styled.h3`
  margin-top: 0;
  margin-bottom: ${(props) => props.theme.space[3]};
  font-size: 2rem;
`;

const mapState = (state: RootState) => ({
  error: getLoginError(state),
  player: getPlayerData(state),
});

const mapDispatch = {
  login: (data: ILoginData) => login(data),
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Login: React.FC<PropsFromRedux> = ({ error, login, player }) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { t } = useTranslation();

  // TODO - Probably should send user to correct page after login
  const doLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login({
      email,
      password,
    });
  };

  if (player && player.id) {
    return (
      <Box mb={4}>
        <Copy>
          <p>
            {t(
              "login.loggedInAs",
              "You are logged in as {{firstName}} {{lastName}}",
              {
                firstName: player.first_name,
                lastName: player.last_name,
              }
            )}
          </p>
        </Copy>
      </Box>
    );
  }

  return (
    <>
      <LoginWrapper>
        <LoginSection>
          <PromoMain>
            <HomeSubHeading id="logIn-Register-Link">
              {t("home.promoHeading", "Play NBA Fantasy")}
            </HomeSubHeading>
            <PromoCopy>
              {t(
                "home.promoCopyn",
                "Create your account or sign in with your NBA ID to play the new NBA Fantasy game. Create a league and invite your friends to see who will win."
              )}
            </PromoCopy>
          </PromoMain>
          <LoginForm onSubmit={doLogin}>
            {error && (
              <Box mb={2}>
                <Alert type="error">
                  {t("login.incorrectDetails", "Incorrect login details")}
                </Alert>
              </Box>
            )}
            <LoginTitle>{t("login.title", "Login")}</LoginTitle>
            <Username>
              <InputField
                id="loginEmail"
                name="email"
                type="email"
                label={t("login.form.emailLabel", "Email")}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </Username>
            <Password>
              <InputField
                id="loginPassword"
                name="password"
                type="password"
                label={t("login.form.pwd", "Password")}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </Password>
            <LoginForgetPassword>
              <LoginButtonWrap>
                <Button
                  type="submit"
                  disabled={!(email && password)}
                  fullwidth="true"
                >
                  {t("login.form.loginCta", "Login")}
                </Button>
              </LoginButtonWrap>

              <a
                href="https://www.nba.com/account/forgot-password"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("login.form.forgotPwd", "Forgot your password?")}
              </a>
            </LoginForgetPassword>
            <ButtonSeparation>
              <span>or</span>
            </ButtonSeparation>
            <CreateAccountButtonWrap>
              <LoginLink to="/player/">
                {t("login.form.create", "Create account")}
              </LoginLink>
            </CreateAccountButtonWrap>
          </LoginForm>
        </LoginSection>
      </LoginWrapper>
    </>
  );
};

export default connector(Login);
