import React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState } from "../../core/store";
import { getElements } from "../../core/store/elements/reducers";
import { IElement } from "../../core/store/elements/types";
import { useTranslation } from "react-i18next";
import { getCurrentEvent } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getPlayerData } from "../../core/store/player/reducers";
import { IPlayer } from "../../core/store/player/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import Title from "../Title";
import EventInfo from "./EventInfo";
import GameweekScheduleTracker from "./GameweekScheduleTracker";
import InjuryTracker from "./InjuryTracker";
import TransfersPanel from "./TransfersPanel";

const StatusWrapper = styled.div`
  max-width: 1220px;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding-right: ${({ theme }) => theme.space[2]};
    padding-left: ${({ theme }) => theme.space[2]};
  }
`;

const StatusCols = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    justify-content: space-between;
    gap: 10rem;
  }
`;

interface IPropsFromState {
  now: IEvent | null;
  player: IPlayer | null;
  teamsById: ITeamsById;
  elements: IElement[];
}

const Status: React.FC<IPropsFromState> = ({
  now,
  player,
  teamsById,
  elements,
}) => {
  const { t } = useTranslation();
  if (!player) {
    return null;
  }

  return (
    <StatusWrapper>
      <Box mt={4} mb={2} px={2}>
        {now && (
          <Title>
            {t("status.titleN", "{{name}} Status", { name: `${now.name}` })}
          </Title>
        )}
      </Box>
      {now && (
        <Box mb={8}>
          <EventInfo />
        </Box>
      )}
      <Box mb={8}>
        <GameweekScheduleTracker />
      </Box>
      {now && (
        <StatusCols>
          <Box mb={8}>
            <TransfersPanel />
          </Box>
          <Box mb={8}>
            <TransfersPanel isOut={true} />
          </Box>
        </StatusCols>
      )}
      <Box mb={8}>
        <InjuryTracker
          newsCount={5}
          showLink={true}
          borderBottom={true}
          teamsById={teamsById}
          elements={elements}
        />
      </Box>
    </StatusWrapper>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  now: getCurrentEvent(state),
  player: getPlayerData(state),
  elements: getElements(state),
  teamsById: getTeamsById(state),
});

export default connect(mapStateToProps)(Status);
