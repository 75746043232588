import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components/macro";
import { getElementTypesById } from "../../core/store/element-types/reducers";
import {
  PitchElementInner,
  PitchElementOuter,
  PitchElementWrap,
} from "../Pitch";
import PlaceholderShirt from "../PlaceholderShirt";
import { isRTL } from "../../utils/locale";
import i18n from "../../i18n";

const StyledPlaceholder = styled.button`
  display: block;
  width: 100%;
  min-height: 232px;
  margin: auto;
  padding: 0;
  border: 0;
  background-color: white;
  text-align: center;
  line-height: 1;
  cursor: pointer;
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.fonts.action};
  ${isRTL(i18n.language)
    ? css`
        font-size: ${(props) => props.theme.fontSizes[3]};
      `
    : css`
        font-size: ${(props) => props.theme.fontSizes[4]};
      `};

  font-weight: bold;
  text-transform: uppercase;
`;

const Abbr = styled.abbr`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    text-decoration: none;
    ::after {
      content: attr(title);
    }
  }
`;

const Span = styled.span`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: none;
  }
`;

interface IProps {
  elementTypeId: number;
  onClick: () => void;
}

const PitchPlaceholder: React.FC<IProps> = ({ elementTypeId, onClick }) => {
  const elementTypesById = useSelector(getElementTypesById);
  const elementType = elementTypesById[elementTypeId];
  const { t } = useTranslation();
  if (!elementType) {
    return null;
  }
  return (
    <PitchElementWrap>
      <PitchElementOuter>
        <PitchElementInner>
          <StyledPlaceholder onClick={onClick}>
            <PlaceholderShirt />
            <Title data-testid="title">
              {t("add", "Add")}{" "}
              <Abbr title={elementType.singular_name}>
                <Span>{elementType.singular_name_short}</Span>
              </Abbr>
            </Title>
          </StyledPlaceholder>
        </PitchElementInner>
      </PitchElementOuter>
    </PitchElementWrap>
  );
};

export default PitchPlaceholder;
