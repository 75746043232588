import * as React from "react";
import styled, { css } from "styled-components/macro";
import { isRTL } from "../utils/locale";
import i18n from "../i18n";

const StyledMedia = styled.div<IStyledMediaProps>`
  display: flex;
  ${(props) =>
    props.centred &&
    css`
      align-items: center;
    `}
`;

const Img = styled.div`
  margin-inline-end: ${({ theme }) => theme.space[2]};
`;

const Body = styled.div`
  flex: 1;
  ${isRTL(i18n.language)
    ? css`
        text-align: right;
      `
    : css`
        text-align: left;
      `}
  /* For ElementInTable webname ellipsis */
  max-width: 100%;
  overflow: hidden;
`;

interface IStyledMediaProps {
  centred: boolean | undefined;
}

interface IMediaProps {
  img: React.ReactNode;
  centred?: boolean;
  children: React.ReactNode;
}

const Media: React.FC<IMediaProps> = ({ img, centred, children }) => (
  <StyledMedia centred={centred}>
    <Img>{img}</Img>
    <Body>{children}</Body>
  </StyledMedia>
);

Media.defaultProps = {
  centred: false,
};

export default Media;
