import * as React from "react";
import { Trans, withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { getElementsById } from "../../core/store/elements/reducers";
import {
  showElementSummary,
  updateElementTypeControl,
} from "../../core/store/elements/thunks";
import { IElement, IElementsById } from "../../core/store/elements/types";
import { getNextEvent } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getSettings } from "../../core/store/game/reducers";
import { ISettings } from "../../core/store/game/types";
import { IPick } from "../../core/store/my-team/types";
import { resetLastChange } from "../../core/store/squad/actions";
import {
  getErrors,
  getLastChange,
  getProposedElements,
  getSavedPicks,
  getSquadMode,
} from "../../core/store/squad/reducers";
import {
  autoComplete,
  removeElement,
  restoreElement,
} from "../../core/store/squad/thunks";
import {
  ILastChange,
  IProposedElements,
  ISavedPicks,
  ISquadErrors,
  SquadMode,
} from "../../core/store/squad/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import {
  formatRawAsISO,
  formatRawAsLocalI18n,
} from "../../core/utils/datetime";
import { integerToMoney } from "../../core/utils/money";
import { dateLocales } from "../../i18n";
import Alert, { AlertWrap } from "../Alert";
import Button from "../Button";
import Copy from "../Copy";
import DeadlineBar from "../DeadlineBar";
import Dialog, { DialogButtonItem } from "../Dialog";
import DialogManager from "../DialogManager";
import TabPanel from "../tabs/TabPanel";
import Tabs from "../tabs/Tabs";
import ElementGroup from "./ElementGroup";
import SaveBar from "./SaveBar";
import Scoreboard from "./Scoreboard";
import SquadPitchUnit from "./SquadPitchUnit";

const ButtonWrap = styled.div`
  display: flex;
  gap: ${(props) => props.theme.space[2]};
  margin: 0 ${(props) => props.theme.space[2]};
`;

const StyledCopy = styled(Copy)`
  margin-bottom: ${(props) => props.theme.space[5]};
`;

const StyledDialogButton = styled(DialogButtonItem)`
  flex: 1;
`;

const PlayerActions = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    width: 50%;
    margin: 0 auto;
  }
`;

//TODO: Move IListTableProps somewhere more abstract?
export interface IListTableProps {
  elementType: number;
  key: string;
  positions: number[];
  renderElementDialog: (element: IElement) => void;
  renderElementMenu: (position: number) => void;
}

interface IOwnProps {
  listTable: (listTableProps: IListTableProps) => void;
  scoreboard: string;
  showSidebar: () => void;
  submitDialog: (handleHide: () => void) => React.ReactNode;
  showListTableAsDefault?: boolean;
}

interface IPropsFromState {
  currencyDivisor: number;
  elementsById: IElementsById;
  errors: ISquadErrors;
  lastChange: ILastChange;
  mode: SquadMode;
  nextEvent: IEvent | null;
  proposedElements: IProposedElements;
  savedPicks: ISavedPicks;
  settings: ISettings | null;
  teamsById: ITeamsById;
}

interface IPropsFromDispatch {
  clearLastChange: () => void;
  removeElement: (position: number) => void;
  restoreElement: (position: number) => void;
  showElementDialog: (elementId: number) => void;
  showElementType: (elementTypeId: number) => void;
  autoPick: () => void;
}

type Props = IOwnProps & IPropsFromState & IPropsFromDispatch & WithTranslation;

interface IState {
  positionForMenu: number;
  showAutoPickDialog: boolean;
}

class CreateSquad extends React.Component<Props, IState> {
  public state: IState = {
    positionForMenu: 0,
    showAutoPickDialog: false,
  };

  public handleShowMenuForElement = (position: number) =>
    this.setState({ positionForMenu: position });

  public handleHideMenuForElement = () => {
    this.setState({ positionForMenu: 0 });
  };

  public removeElement = (position: number) => {
    this.handleHideMenuForElement();
    this.props.removeElement(position);
  };

  public restoreElement = (position: number) => {
    this.handleHideMenuForElement();
    this.props.restoreElement(position);
  };

  public showElementType = (elementType: number) => {
    this.handleHideMenuForElement();
    this.props.showElementType(elementType);
  };

  public showDialog = (element: IElement) => {
    this.props.showElementDialog(element.id);
    this.handleHideMenuForElement();
  };

  public showAutoPickDialog = () => {
    this.setState({ showAutoPickDialog: true });
  };

  public hideAutoPickDialog = () => {
    this.setState({ showAutoPickDialog: false });
  };

  public renderMenu() {
    const { t } = this.props;
    let element: IElement | undefined;
    let mode: "remove" | "restore" | undefined;
    if (this.props.proposedElements[this.state.positionForMenu]) {
      element = this.props.proposedElements[this.state.positionForMenu];
      mode = "remove";
    } else if (this.props.savedPicks[this.state.positionForMenu]) {
      element =
        this.props.elementsById[
          this.props.savedPicks[this.state.positionForMenu].element
        ];
      mode = "restore";
    }
    if (!mode || !element) {
      return null;
    }
    return (
      <Dialog closeDialog={this.handleHideMenuForElement}>
        <Dialog.Header closeDialog={this.handleHideMenuForElement}>
          {`${element.first_name} ${element.second_name}`}
        </Dialog.Header>
        <Dialog.Body>
          <PlayerActions>
            {mode === "remove" ? (
              <DialogButtonItem>
                <Button
                  onClick={() => this.removeElement(this.state.positionForMenu)}
                  fullwidth="true"
                >
                  {t("createSquad.removePlayer", "Remove Player")}
                </Button>
              </DialogButtonItem>
            ) : mode === "restore" ? (
              <>
                <DialogButtonItem>
                  <Button
                    onClick={() =>
                      this.restoreElement(this.state.positionForMenu)
                    }
                    fullwidth="true"
                    variant="tertiary"
                  >
                    {t("createSquad.restorePlayer", "Restore Player")}
                  </Button>
                </DialogButtonItem>
                <DialogButtonItem>
                  <Button
                    onClick={() => this.showElementType(element!.element_type)}
                    fullwidth="true"
                  >
                    {t("createSquad.replacePlayer", "Select Replacement")}
                  </Button>
                </DialogButtonItem>
              </>
            ) : (
              ""
            )}
            <DialogButtonItem>
              <Button
                onClick={() => this.showDialog(element as IElement)}
                fullwidth="true"
                variant="secondary"
              >
                {t("createSquad.viewInformation", "View Information")}
              </Button>
            </DialogButtonItem>
          </PlayerActions>
        </Dialog.Body>
      </Dialog>
    );
  }

  public renderElementValueForPosition(pos: number) {
    const element: IElement = this.props.proposedElements[pos];
    const pick: IPick | undefined = this.props.savedPicks[pos];
    // Initial element selection (squad selection)
    if (!pick && element) {
      return integerToMoney(element.now_cost, this.props.currencyDivisor);
    }
    // Removed element (transfers)
    if (pick && !element) {
      return integerToMoney(pick.selling_price, this.props.currencyDivisor);
    }
    // Original element (transfers)
    if (pick && element && pick.element === element.id) {
      return integerToMoney(pick.selling_price, this.props.currencyDivisor);
    }
    // Replaced element (transfers)
    if (pick && element && pick.element !== element.id) {
      return integerToMoney(element.now_cost, this.props.currencyDivisor);
    }
    return null;
  }

  public componentDidMount() {
    this.props.clearLastChange();
    Object.keys(this.props.proposedElements).length === 0 &&
      this.showAutoPickDialog();
  }

  public render() {
    const {
      t,
      autoPick,
      elementsById,
      errors,
      i18n,
      listTable,
      lastChange,
      mode,
      nextEvent,
      scoreboard,
      settings,
      showSidebar,
      submitDialog,
      teamsById,
      showListTableAsDefault,
    } = this.props;

    if (!settings) {
      return null;
    }

    let latestAction: React.ReactNode = null;
    if (lastChange.type === "addition") {
      latestAction = (
        <Alert type="success">
          <Trans i18nKey="createSquad.alert.elementAddedn">
            <strong>
              {{ playerName: elementsById[lastChange.element].web_name }}
            </strong>{" "}
            has been added to your roster.
          </Trans>
        </Alert>
      );
    } else if (lastChange.type === "removal") {
      latestAction = (
        <Alert type="success">
          <Trans i18nKey="createSquad.alert.elementRemovedn">
            <strong>
              {{ playerName: elementsById[lastChange.element].web_name }}
            </strong>{" "}
            has been removed from your roster.
          </Trans>
        </Alert>
      );
    }

    // Button enablers
    const canEnter = !Boolean(Object.keys(errors).length);

    // Helper functions to generate props used by multiple component instances
    const sharedPitchElementProps = (pos: number) => ({
      pos,
      renderElementMenu: this.handleShowMenuForElement,
      elementValue: this.renderElementValueForPosition(pos),
      showSidebar,
    });

    const sharedSquadListTableProps = (
      pos: number[],
      elementType: number,
      key: string
    ) => ({
      elementType,
      key,
      positions: pos,
      renderElementDialog: this.showDialog,
      renderElementMenu: this.handleShowMenuForElement,
    });

    const submitButtonText = {
      selection: t("createSquad.enterSquad", "Enter roster"),
      transfers: t("createSquad.makeTransfers", "Make Transactions"),
    };

    const positionsByElementType: {
      [index: string]: number[];
    } = {
      1: [1, 2, 3, 4, 5],
      2: [6, 7, 8, 9, 10],
    };

    const showTabsInReverseOrder = showListTableAsDefault;

    return (
      <div>
        {nextEvent && (
          <Box mb={2}>
            <DeadlineBar
              deadlineISO={formatRawAsISO(nextEvent.deadline_time)}
              deadlineLocal={formatRawAsLocalI18n(
                nextEvent.deadline_time,
                dateLocales[i18n.language]
              )}
              headingText={t(
                "createSquad.eventDeadline",
                "{{ eventName }} deadline",
                {
                  eventName: nextEvent.name,
                }
              )}
            />
          </Box>
        )}
        <Scoreboard scoreboard={scoreboard} />
        {errors.overTeamLimit && (
          <AlertWrap>
            <Alert type="error">
              {t(
                "createSquad.errors.overTeamLimit",
                "Too many players selected from"
              )}{" "}
              <strong>
                {errors.overTeamLimit
                  .map((team) => teamsById[team].name)
                  .join(", ")}
              </strong>
            </Alert>
          </AlertWrap>
        )}
        {latestAction && <AlertWrap>{latestAction}</AlertWrap>}
        <Box mt={4}>
          <Tabs centered>
            {[
              <TabPanel
                label={t("createSquad.listView", "List View")}
                link="pitch"
              >
                <Box mb={4} mx="-1px">
                  {Object.keys(positionsByElementType)
                    .reverse()
                    .map((et) =>
                      listTable(
                        sharedSquadListTableProps(
                          positionsByElementType[et],
                          parseInt(et, 10),
                          et
                        )
                      )
                    )}
                </Box>
              </TabPanel>,
              <TabPanel
                label={t("createSquad.pitchView", "Court View")}
                link="pitch"
              >
                <Box mb={4}>
                  <ElementGroup elementTypeId={2}>
                    <SquadPitchUnit {...sharedPitchElementProps(6)} />
                    <SquadPitchUnit {...sharedPitchElementProps(7)} />
                    <SquadPitchUnit {...sharedPitchElementProps(8)} />
                    <SquadPitchUnit {...sharedPitchElementProps(9)} />
                    <SquadPitchUnit {...sharedPitchElementProps(10)} />
                  </ElementGroup>
                </Box>
                <Box mb={4}>
                  <ElementGroup elementTypeId={1}>
                    <SquadPitchUnit {...sharedPitchElementProps(1)} />
                    <SquadPitchUnit {...sharedPitchElementProps(2)} />
                    <SquadPitchUnit {...sharedPitchElementProps(3)} />
                    <SquadPitchUnit {...sharedPitchElementProps(4)} />
                    <SquadPitchUnit {...sharedPitchElementProps(5)} />
                  </ElementGroup>
                </Box>
              </TabPanel>,
            ].sort((a, b) => (showTabsInReverseOrder ? 1 : -1))}
          </Tabs>
        </Box>

        {this.renderMenu()}
        <DialogManager
          render={(showDialog, handleShow, handleHide) => (
            <>
              <SaveBar>
                <Button
                  onClick={handleShow}
                  disabled={!canEnter}
                  fullwidth="true"
                >
                  {submitButtonText[mode]}
                </Button>
              </SaveBar>
              {showDialog && submitDialog(handleHide)}
            </>
          )}
        />

        {this.state.showAutoPickDialog && (
          <Dialog closeDialog={this.hideAutoPickDialog}>
            <Dialog.Header closeDialog={this.hideAutoPickDialog}>
              {t("createSquad.onboarding.titlen", "Roster Selection")}
            </Dialog.Header>
            <Dialog.Body>
              <StyledCopy>
                <p>
                  {t(
                    "createSquad.onboarding.p1n",
                    "It's time to pick your team. Auto pick to get started quickly or choose your roster yourself."
                  )}
                </p>
                <p>
                  {t(
                    "createSquad.onboarding.p2n",
                    "You can sign and drop players for free before your first gameday deadline in the transactions tab. After this deadline, you'll get two free changes every week and any extra changes to your roster will cost 100 fantasy points."
                  )}
                </p>
                <p>
                  {t(
                    "createSquad.onboarding.p3n",
                    "You can pick a maximum of 2 players from one NBA team."
                  )}
                </p>
              </StyledCopy>
              <ButtonWrap>
                <StyledDialogButton>
                  <Button
                    onClick={() => {
                      autoPick();
                      this.hideAutoPickDialog();
                    }}
                    fullwidth="true"
                  >
                    {t("createSquad.onboarding.autoPickn", "Auto Pick")}
                  </Button>
                </StyledDialogButton>
                <StyledDialogButton>
                  <Button onClick={this.hideAutoPickDialog} fullwidth="true">
                    {t(
                      "createSquad.onboarding.entryPickn",
                      "I'll pick my squad!"
                    )}
                  </Button>
                </StyledDialogButton>
              </ButtonWrap>
            </Dialog.Body>
          </Dialog>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IPropsFromState => ({
  currencyDivisor: 10,
  elementsById: getElementsById(state),
  errors: getErrors(state),
  lastChange: getLastChange(state),
  mode: getSquadMode(state),
  nextEvent: getNextEvent(state),
  proposedElements: getProposedElements(state),
  savedPicks: getSavedPicks(state),
  settings: getSettings(state),
  teamsById: getTeamsById(state),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  ownProps: IOwnProps
): IPropsFromDispatch => ({
  autoPick: () => {
    if (!dispatch(autoComplete())) {
      // We should do something :-)
      window.console.log("Failed to autocomplete");
    }
  },
  clearLastChange: () => dispatch(resetLastChange()),
  removeElement: (position) => dispatch(removeElement(position)),
  restoreElement: (position) => dispatch(restoreElement(position)),
  showElementDialog: (elementId) => dispatch(showElementSummary(elementId)),
  showElementType: (elementTypeId) => {
    dispatch(updateElementTypeControl(elementTypeId));
    ownProps.showSidebar();
  },
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CreateSquad)
);
