import orderBy from "lodash/orderBy";
import { size } from "polished";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { RootState } from "../../core/store";
import { getElements } from "../../core/store/elements/reducers";
import { IElement } from "../../core/store/elements/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import { ReactComponent as ArrowDown } from "../../img/icons/transfer-arrow-down.svg";
import { ReactComponent as ArrowUp } from "../../img/icons/transfer-arrow-up.svg";
import Badge from "../Badge";
import BoldLink from "../BoldLink";
import ElementDialogButton from "../ElementDialogButton";
import { ElementRow, ElementTable, PrimaryCell } from "../ElementTable";
import Panel from "../Panel";
import StatusTableElement from "./StatusTableElement";

const BodyArrowIn = styled(ArrowUp)`
  ${size(16)}
`;

const BodyArrowOut = styled(ArrowDown)`
  ${size(16)}
`;

const StyledPrimaryCell = styled(PrimaryCell)`
  font-style: italic;
`;

const TransfersTable = styled(ElementTable)`
  th:not(:nth-child(4)) {
    text-align: center;
  }
`;

const StyledThead = styled.thead`
  visibility: collapse;
`;

const StatusCol = styled.th`
  width: 12%;
`;

const PosCol = styled.th`
  width: 14%;
  text-align: center;
`;

const BadgeCol = styled.th`
  width: 5%;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 10%;
  }
`;

const PlayerCol = styled.th`
  width: 32%;
`;

const TransfersCol = styled.th`
  width: 16%;
`;

const BoldLinkWrap = styled.div`
  text-align: end;
`;

interface IPropsFromState {
  elements: IElement[];
  teamsById: ITeamsById;
}

interface IOwnProps {
  isOut?: boolean;
}

type Props = IPropsFromState & IOwnProps;

const TransfersPanel: React.FC<Props> = ({ elements, isOut, teamsById }) => {
  const { t } = useTranslation();
  const stat = isOut ? "transfers_out_event" : "transfers_in_event";
  const transferStatus = isOut
    ? t("transfersPanel.out", "Waived")
    : t("transfersPanel.in", "Picked Up");

  return (
    <Panel>
      <Panel.Header
        upperCase={true}
        title={t("transfersPanel.heading", "Most {{ inOut }} Players", {
          inOut: transferStatus,
        })}
      ></Panel.Header>
      <Panel.Body>
        <TransfersTable>
          <StyledThead>
            <tr>
              <StatusCol>&nbsp;</StatusCol>
              <BadgeCol scope="col">&nbsp;</BadgeCol>
              <PlayerCol scope="col">&nbsp;</PlayerCol>
              <PosCol>&nbsp;</PosCol>
              <TransfersCol>&nbsp;</TransfersCol>
            </tr>
          </StyledThead>
          <tbody>
            {orderBy(elements, [stat], ["desc"])
              .slice(0, 5)
              .map((e) => {
                const team = teamsById[e.team];
                return (
                  <ElementRow key={e.id}>
                    <td>
                      <ElementDialogButton elementId={e.id} variant="list" />
                    </td>
                    <PrimaryCell>
                      <Badge teamId={team.id} width="40px" />
                    </PrimaryCell>
                    <td>
                      <StatusTableElement element={e} teamsById={teamsById} />
                    </td>
                    <PrimaryCell>
                      {isOut ? <BodyArrowOut /> : <BodyArrowIn />}
                    </PrimaryCell>
                    <StyledPrimaryCell>{e[stat]}</StyledPrimaryCell>
                  </ElementRow>
                );
              })}
          </tbody>
        </TransfersTable>
      </Panel.Body>
      <Panel.Footer borderBottom={true}>
        <BoldLinkWrap>
          <BoldLink color={"blueDark"} to={`/statistics/${stat}`}>
            {t("transfersPanel.seeMore", "See more")}
          </BoldLink>
        </BoldLinkWrap>
      </Panel.Footer>
    </Panel>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  elements: getElements(state),
  teamsById: getTeamsById(state),
});

export default connect(mapStateToProps)(TransfersPanel);
