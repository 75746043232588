import heroImg1x from "../img/hero@1x.jpg";
import heroImg2x from "../img/hero@2x.jpg";
import heroImgMob1x from "../img/hero-img-mob@1x.jpg";
import heroImgMob2x from "../img/hero-img-mob@2x.jpg";
import styled from "styled-components";
import { hiDPI } from "polished";

const HomeHeroWrap = styled.div`
  background-image: url(${heroImgMob1x});
  ${hiDPI(2)} {
    background-image: url(${heroImgMob2x});
  }
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[2]};
  padding-top: 220px;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    background-image: url(${heroImg1x});
    ${hiDPI(2)} {
      background-image: url(${heroImg2x});
    }
    margin: ${({ theme }) => theme.space[2]};
    padding-top: 140px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding-top: 180px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    padding-top: 230px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding-top: 270px;
  }
`;

const HomeHero = () => {
  return <HomeHeroWrap />;
};

export default HomeHero;
