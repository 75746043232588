import { size } from "polished";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { getElementTypesBySquadPosition } from "../../core/store/element-types/reducers";
import { IElementTypesBySquadPosition } from "../../core/store/element-types/types";
import { updateElementTypeControl } from "../../core/store/elements/thunks";
import { getNextEvent, getNextNEvents } from "../../core/store/events/reducers";
import { getFixturesByEvent } from "../../core/store/fixtures/reducers";
import { getProposedElements } from "../../core/store/squad/reducers";
import { IProposedElements } from "../../core/store/squad/types";
import { integerToMoney } from "../../core/utils/money";
import { ReactComponent as NoFixtureHyphen } from "../../img/icons/no-fixture-hyphen.svg";
import SmallPlusIcon from "../../img/icons/plus-small.svg";
import PlayerPlaceholder from "../../img/player-image-blank.png";
import { integerToDecimal } from "../../utils/decimal";
import { getPrefixedEventShortName } from "../../utils/events";
import { getNextElementFixtures } from "../../utils/fixtures";
import Badge from "../Badge";
import ElementDialogButton from "../ElementDialogButton";
import ElementInTable from "../ElementInTable";
import { ElementCell, ElementRow, ElementTable } from "../ElementTable";
import { ScrollTable } from "../Table";
import Tooltip, { TooltipLabel } from "../Tooltip";

const FilterElTypeButton = styled.button`
  padding: 10px 0 9px;
  border: 0;
  background-color: transparent;
  font-size: 1.3rem;
  white-space: normal;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const PlaceHolderImageWrap = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space[5]};
  padding-left: ${({ theme }) => theme.space[1]};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding-left: ${({ theme }) => theme.space[2]};
  }
`;

const ImageIconWrap = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space[2]};
  align-items: center;
`;

const StyledElementType = styled.span`
  font-weight: 500;
  text-transform: uppercase;
`;

const SquadListElement = styled.th`
  width: 28%;
  font-family: ${(props) => props.theme.fonts.body};

  ${StyledElementType} {
    font-size: ${(props) => props.theme.fontSizes[3]};
  }
`;

const SquadListStatus = styled.th`
  width: 8%;
`;

const SquadListCost = styled.th`
  width: 9%;
`;

const SquadListSelected = styled.th`
  width: 9%;
`;

const SquadListPoints = styled.th`
  width: 11%;
`;

const SquadListFixture = styled.th`
  width: 10%;
  text-align: center;
`;

const FixtureCell = styled.td`
  text-align: center;
`;

const PlaceholderImagePlus = styled.div`
  background-image: url(${SmallPlusIcon});
  background-size: contain;
  background-repeat: no-repeat;
  ${size(17)}
  margin: 0 ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    background-size: contain;
    ${size(30)}
    margin: 0;
  }
`;

const PlaceholderImagePlayer = styled.div`
  flex: 2;
  background-image: url(${PlayerPlaceholder});
  background-repeat: no-repeat;
  ${size(50, 60)}
`;

const NoFixture = styled(NoFixtureHyphen)`
  ${size(16)}
`;

interface ISquadListRowProps {
  elementTypesByPosition: IElementTypesBySquadPosition | null;
  proposedElements: IProposedElements;
  pos: number;
  renderElementMenu: (position: number) => void;
  showElementType: (elementTypeId: number) => void;
}

const SquadListRow: React.FC<ISquadListRowProps> = ({
  elementTypesByPosition,
  proposedElements,
  pos,
  renderElementMenu,
  showElementType,
}) => {
  const { t } = useTranslation();

  const element = proposedElements[pos];
  const fixturesByEvent = useSelector(getFixturesByEvent);
  const nextEvent = useSelector(getNextEvent);
  const neededFixtures = 5;
  const elementFixtures =
    element &&
    getNextElementFixtures(nextEvent, neededFixtures, fixturesByEvent, element);

  if (!elementTypesByPosition) {
    return null;
  }

  return (
    <ElementRow>
      {element ? (
        <>
          <td>
            <ElementDialogButton elementId={element.id} variant="list" />
          </td>
          <ElementCell>
            <ElementInTable
              renderElementMenu={() => renderElementMenu(pos)}
              elementId={element.id}
            />
          </ElementCell>
          <td>{integerToMoney(element.now_cost, 10)}</td>
          <td>{element.selected_by_percent}</td>
          <td>{integerToDecimal(element.total_points, 10)}</td>

          {elementFixtures.map((fixture) =>
            fixture.code ? (
              <FixtureCell key={fixture.code}>
                <Badge
                  teamId={
                    fixture.team_a === element.team
                      ? fixture.team_h
                      : fixture.team_a
                  }
                  width="30px"
                />
              </FixtureCell>
            ) : (
              <FixtureCell key={fixture.event!}>
                <NoFixture />
              </FixtureCell>
            )
          )}
        </>
      ) : (
        <>
          <td colSpan={10}>
            <FilterElTypeButton
              onClick={() =>
                showElementType(elementTypesByPosition[pos].thisType.id)
              }
            >
              <PlaceHolderImageWrap>
                <PlaceholderImagePlus />
                <ImageIconWrap>
                  <PlaceholderImagePlayer />
                  {t("squad.selectElement", "Select {{position}} Player", {
                    position:
                      elementTypesByPosition[pos].thisType.singular_name,
                  })}
                </ImageIconWrap>
              </PlaceHolderImageWrap>
            </FilterElTypeButton>
          </td>
        </>
      )}
    </ElementRow>
  );
};

interface IPropsFromState {
  elementTypesByPosition: IElementTypesBySquadPosition | null;
  proposedElements: IProposedElements;
}

interface IPropsFromDispatch {
  showElementType: (elementTypeId: number) => void;
}

interface ISquadListTableProps {
  elementType: number;
  positions: number[];
  renderElementMenu: (pos: number) => void;
}

type Props = IPropsFromState & IPropsFromDispatch & ISquadListTableProps;

const SquadListTable: React.FC<Props> = ({
  elementType: elementTypeId,
  elementTypesByPosition,
  positions,
  ...rest
}) => {
  const { t } = useTranslation();

  const next5Events = useSelector(getNextNEvents(5));

  if (!elementTypesByPosition) {
    return null;
  }
  return (
    <ScrollTable $wide={true}>
      <ElementTable elementTypeId={elementTypeId}>
        <thead>
          <tr>
            <SquadListStatus>&nbsp;</SquadListStatus>
            <SquadListElement>
              <StyledElementType>
                {elementTypesByPosition[positions[0]].thisType.plural_name}
              </StyledElementType>
            </SquadListElement>
            <SquadListCost>
              <Tooltip content={t("squadListTabel.price", "Salary")}>
                <TooltipLabel>$</TooltipLabel>
              </Tooltip>
            </SquadListCost>
            <SquadListSelected>
              <Tooltip content={t("squadListTabel.selectedPct", "Value")}>
                <TooltipLabel>
                  {t("squadListTabel.label.selectedPct", "VAL")}
                </TooltipLabel>
              </Tooltip>
            </SquadListSelected>
            <SquadListPoints>
              <Tooltip
                content={t("squadListTabel.totalPoints", "Total points")}
              >
                <TooltipLabel>
                  {t("squadListTabel.label.totalPoints", "TP")}
                </TooltipLabel>
              </Tooltip>
            </SquadListPoints>

            {next5Events.length
              ? next5Events.map((e) => (
                  <SquadListFixture scope="colgroup" key={e.id}>
                    {getPrefixedEventShortName(e.name)}
                  </SquadListFixture>
                ))
              : null}
          </tr>
        </thead>
        <tbody>
          {positions.map((p) => (
            <SquadListRow
              key={p}
              pos={p}
              elementTypesByPosition={elementTypesByPosition}
              {...rest}
            />
          ))}
        </tbody>
      </ElementTable>
    </ScrollTable>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  elementTypesByPosition: getElementTypesBySquadPosition(state),
  proposedElements: getProposedElements(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  showElementType: (elementTypeId) =>
    dispatch(updateElementTypeControl(elementTypeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SquadListTable);
