import { ellipsis } from "polished";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";
import { IElement } from "../../core/store/elements/types";
import { ITeamsById } from "../../core/store/teams/types";
import i18n from "../../i18n";
import { isRTL } from "../../utils/locale";
import TeamCode from "../TeamCode";
import Tooltip, { TooltipLabel } from "../Tooltip";

interface IProps {
  element: IElement;
  teamsById: ITeamsById;
}

interface IStyledElementData {
  elementType: number;
}

const ElementList = styled.ul`
  margin: 0;
`;

export const ListItemStyles = css`
  list-style: none;
  text-align: start;
`;

const FirstName = styled.li`
  ${ListItemStyles}
`;

const SecondName = styled.li`
  ${ListItemStyles}
  ${ellipsis()};
  font-weight: 600;
`;

const Team = styled.li`
  ${ListItemStyles}
`;

const TeamPosition = styled.p<IStyledElementData>`
  display: inline-block;
  margin: 0;
  padding-inline-start: ${({ theme }) => theme.space[2]};
  font-style: italic;
  ${(props) =>
    props.elementType &&
    css`
      color: ${props.theme.colors.elementTypes[props.elementType].colors[0]};
    `}
`;

const StatusTableElement = ({ element, teamsById }: IProps) => {
  const { t } = useTranslation();
  const team = teamsById[element.team];
  const courtPosition =
    element.element_type === 1
      ? t("news.backcourtShort", "BC")
      : t("news.frontcourtShort", "FC");
  return (
    <ElementList>
      <FirstName>{element.first_name}</FirstName>
      <SecondName>{element.second_name}</SecondName>
      <Team>
        {isRTL(i18n.language) && (
          <TeamPosition elementType={element.element_type}>
            {courtPosition}
          </TeamPosition>
        )}
        <TeamCode isSmall={true} teamId={element.team}>
          <Tooltip content={team.name}>
            <TooltipLabel $textDecoration={false}>
              {team.short_name}
            </TooltipLabel>
          </Tooltip>
        </TeamCode>
        {!isRTL(i18n.language) && (
          <TeamPosition elementType={element.element_type}>
            {courtPosition}
          </TeamPosition>
        )}
      </Team>
    </ElementList>
  );
};
export default StatusTableElement;
