import { rgba } from "polished";
import styled, { css } from "styled-components/macro";
import { SubStatus } from "../core/store/my-team/types";
import i18n from "../i18n";
import { isRTL } from "../utils/locale";
import { SquadListFixture } from "./squad/TransfersListTable";
import Table from "./Table";

interface IProps {
  elementTypeId?: number;
}

const textStyleAdjustmentLocales: string[] = ["ar", "de", "es", "he"];
const gradientDirection = isRTL(i18n.language) ? "to left" : "to right";

export const ElementTable = styled(Table)<IProps>`
  width: 100%;
  white-space: nowrap;
  table-layout: fixed;
  background-color: white;
  border-collapse: collapse;

  th,
  td {
    padding-top: 1rem;
    padding-bottom: 1rem;

    font-size: 1.1rem;
    @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
      font-size: 1.4rem;
    }
  }
  th {
    ${textStyleAdjustmentLocales.includes(i18n.language) &&
    css`
      overflow-wrap: break-word;
      hyphens: auto;
      white-space: normal;
      font-size: 1.2rem;
    `}
  }

  ${SquadListFixture} {
    text-align: center;
  }

  ${(props) =>
    props.elementTypeId &&
    css`
      thead {
        tr {
          background-image: linear-gradient(
            ${gradientDirection},
            ${props.theme.colors.elementTypes[props.elementTypeId].colors[0]}
              75%,
            ${props.theme.colors.elementTypes[props.elementTypeId].colors[1]}
          );
          background-attachment: fixed;
        }
        th {
          color: #fff;
        }
      }
    `}
`;

export const PrimaryCell = styled.td`
  position: relative;
  text-align: center;
`;

export const PrimaryCellLined = styled(PrimaryCell)`
  ::after {
    content: "";
    position: absolute;
    top: 1rem;
    left: 0;
    bottom: 1rem;
    display: block;
    width: 2px;
    background: white;
  }
`;

interface IElementRowProps {
  variant?: SubStatus;
}

export const ElementRow = styled.tr<IElementRowProps>`
  td {
    padding: ${(props) => props.theme.space[1]};

    :first-child {
      padding: 0.2rem;
    }
  }

  ${(props) =>
    props.variant === "instigator" &&
    css`
      background-color: ${rgba("yellow", 0.6)};
    `}

  ${(props) =>
    props.variant === "target" &&
    css`
      background-color: ${rgba("#f60", 0.6)};
    `}

  ${(props) =>
    props.variant === "invalid" &&
    css`
      opacity: 0.6;
    `}
`;

interface IElementCellProps {
  bold?: boolean;
}

export const ElementCell = styled.td<IElementCellProps>`
  position: relative;

  ::after {
    content: "";
    position: absolute;
    top: 1rem;
    right: 0;
    bottom: 1rem;
    display: block;
    width: 2px;
    background: white;
  }
`;
