import { navigate, RouteComponentProps } from "@reach/router";
import qs from "qs";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box, Flex } from "rebass/styled-components";
import { RootState, ThunkDispatch } from "../../core/store";
import {
  getCurrentEvent,
  getEventsById,
} from "../../core/store/events/reducers";
import { IEvent, IEventsById } from "../../core/store/events/types";
import {
  getClassicLeague,
  getClassicNewEntries,
  getClassicStandings,
} from "../../core/store/leagues/reducers";
import { fetchClassicLeagueStandings } from "../../core/store/leagues/thunks";
import {
  IClassicStandingsData,
  ILeague,
  INewEntriesData,
} from "../../core/store/leagues/types";
import { getStartedPhases } from "../../core/store/phases/reducers";
import { IPhase } from "../../core/store/phases/types";
import { getPlayerData } from "../../core/store/player/reducers";
import { IPlayer } from "../../core/store/player/types";
import { integerToDecimal } from "../../utils/decimal";
import Alert from "../Alert";
import ButtonLink from "../ButtonLink";
import Copy from "../Copy";
import { InlineSelectField } from "../FieldRenderers";
import { ControlArrowEnd, ControlArrowStart } from "../icons/Arrows";
import { Main, Wrapper } from "../Layout";
import Link from "../Link";
import { Pager, PagerButton, PagerButtonNext } from "../Pager";
import SubHeading from "../SubHeading";
import Table from "../Table";
import Tooltip, { TooltipLabel } from "../Tooltip";
import Movement from "./Movement";
import StandingsHeading from "./StandingsHeading";
import StandingsRow from "./StandingsRow";

type OwnProps = RouteComponentProps<{ leagueId: string }>;

interface IPropsFromState {
  league: ILeague | null;
  leagueNumber: number;
  newEntries: INewEntriesData | null;
  now: IEvent | null;
  pageNewEntries: number;
  pageStandings: number;
  phase: number;
  phases: IPhase[];
  player: IPlayer | null;
  standings: IClassicStandingsData | null;
  eventsById: IEventsById;
}

interface IPropsFromDispatch {
  fetchStandings: (
    leagueId: number,
    phaseId: number,
    pageNewEntries: number,
    pageStandings: number
  ) => void;
}

type Props = OwnProps & IPropsFromState & IPropsFromDispatch & WithTranslation;

class StandingsClassic extends React.Component<Props> {
  public fetchLeagueStandings() {
    if (this.props.leagueNumber) {
      this.props.fetchStandings(
        this.props.leagueNumber,
        this.props.phase,
        this.props.pageNewEntries,
        this.props.pageStandings
      );
    }
  }

  public componentDidMount() {
    this.fetchLeagueStandings();
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      prevProps.pageNewEntries !== this.props.pageNewEntries ||
      prevProps.pageStandings !== this.props.pageStandings ||
      prevProps.phase !== this.props.phase
    ) {
      this.fetchLeagueStandings();
    }
  }

  public buildLink(
    phase: number,
    pageNewEntries: number,
    pageStandings: number
  ) {
    return `/leagues/${
      this.props.league!.id
    }/standings/c?phase=${phase}&page_new_entries=${pageNewEntries}&page_standings=${pageStandings}`;
  }

  public handleChange = (e: React.FormEvent<HTMLSelectElement>) =>
    navigate(this.buildLink(parseInt(e.currentTarget.value, 10), 1, 1));

  public render() {
    const {
      t,
      league,
      newEntries,
      now,
      pageNewEntries,
      pageStandings,
      phase,
      phases,
      player,
      standings,
      leagueNumber,
      eventsById,
    } = this.props;

    if (!league) {
      return null;
    }
    const started = now && league.start_event <= now.id;
    return (
      <>
        <Wrapper>
          <Main>
            <Copy>
              <StandingsHeading
                type="classic"
                codePrivacy={league.code_privacy}
                leagueTitle={league.name}
                leagueNumber={leagueNumber}
              />
              {league.start_event > 1 && (
                <Box m={2}>
                  <Alert type="info">
                    {started
                      ? t(
                          "myLeagues.standingsClassic.alertStarted",
                          "League scoring started in {{startEvent}}",
                          { startEvent: eventsById[league.start_event].name }
                        )
                      : t(
                          "myLeagues.standingsClassic.alertWillStart",
                          "League scoring will start in {{startEvent}}",
                          { startEvent: eventsById[league.start_event].name }
                        )}
                  </Alert>
                </Box>
              )}

              <Box mb={4}>
                <form>
                  <InlineSelectField
                    id="selectPhase"
                    name="selectPhase"
                    label={t("standingsClassic.selectPhase", "Select phase")}
                    onChange={this.handleChange}
                    value={phase}
                  >
                    {phases.map((p) => (
                      <option
                        value={p.id}
                        key={p.id}
                        aria-selected={phase === p.id}
                      >
                        {p.name}
                      </option>
                    ))}
                  </InlineSelectField>
                </form>
              </Box>

              <Table>
                <thead>
                  <tr>
                    <th>{t("standingsClassic.rank", "Rank")}</th>
                    <th>
                      {t(
                        "standingsClassic.teamAndManagern",
                        "Team & General Manager"
                      )}
                    </th>
                    <th>
                      <Tooltip
                        content={t(
                          "standingsClassic.roundPointsn",
                          "Gameday fantasy points"
                        )}
                      >
                        <TooltipLabel>GD</TooltipLabel>
                      </Tooltip>
                    </th>
                    <th>
                      <Tooltip
                        content={t(
                          "standingsClassic.totalPoints",
                          "Total points"
                        )}
                      >
                        <TooltipLabel>TOT</TooltipLabel>
                      </Tooltip>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {standings &&
                    standings.results.map((ls) => (
                      <StandingsRow
                        mine={player !== null && player.entry === ls.entry}
                        key={ls.entry}
                      >
                        <td>
                          <Flex alignItems="center">
                            <Box mr={1}>{ls.rank}</Box>
                            <Movement lastRank={ls.last_rank} rank={ls.rank} />
                          </Flex>
                        </td>
                        <td>
                          {now ? (
                            <Link to={`/entry/${ls.entry}/event/${now.id}`}>
                              <strong>{ls.entry_name}</strong>
                            </Link>
                          ) : (
                            <strong>{ls.entry_name}</strong>
                          )}
                          <br />
                          {ls.player_name}
                        </td>
                        <td>{integerToDecimal(ls.event_total, 10)}</td>
                        <td>{integerToDecimal(ls.total, 10)}</td>
                      </StandingsRow>
                    ))}
                </tbody>
              </Table>
              {standings && (
                <Pager>
                  <PagerButton>
                    {pageStandings > 1 && (
                      <ButtonLink
                        to={this.buildLink(
                          phase,
                          pageNewEntries,
                          pageStandings - 1
                        )}
                        variant="secondary"
                      >
                        <ControlArrowStart />
                        {t("standingsClassic.previous", "Previous")}
                      </ButtonLink>
                    )}
                  </PagerButton>
                  <PagerButtonNext>
                    {standings.has_next && (
                      <ButtonLink
                        to={this.buildLink(
                          phase,
                          pageNewEntries,
                          pageStandings + 1
                        )}
                        variant="secondary"
                      >
                        {t("standingsClassic.next", "Next")}
                        <ControlArrowEnd />
                      </ButtonLink>
                    )}
                  </PagerButtonNext>
                </Pager>
              )}
              {newEntries && newEntries.results.length > 0 && (
                <>
                  <SubHeading>
                    {t("standingsClassic.newEntries", "New Entries")}
                  </SubHeading>
                  <Table>
                    <thead>
                      <tr>
                        <th>{t("standingsClassic.team", "Team")}</th>
                        <th>
                          {t("standingsClassic.managern", "General Manager")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {newEntries.results.map((ne) => (
                        <tr key={ne.entry}>
                          <td>{ne.entry_name}</td>
                          <td>
                            <Link to={`/entry/${ne.entry}/history`}>
                              {ne.player_first_name} {ne.player_last_name}
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Pager>
                    <PagerButton>
                      {pageNewEntries > 1 && (
                        <ButtonLink
                          to={this.buildLink(
                            phase,
                            pageNewEntries - 1,
                            pageStandings
                          )}
                          variant="secondary"
                        >
                          <ControlArrowStart />
                          {t("standingsClassic.previous", "Previous")}
                        </ButtonLink>
                      )}
                    </PagerButton>
                    <PagerButtonNext>
                      {newEntries.has_next && (
                        <ButtonLink
                          to={this.buildLink(
                            phase,
                            pageNewEntries + 1,
                            pageStandings
                          )}
                          variant="secondary"
                        >
                          {t("standingsClassic.next", "Next")}
                          <ControlArrowEnd />
                        </ButtonLink>
                      )}
                    </PagerButtonNext>
                  </Pager>
                </>
              )}
            </Copy>
          </Main>
        </Wrapper>
      </>
    );
  }
}

export { StandingsClassic as StandingClassicTest };

const mapStateToProps = (
  state: RootState,
  ownProps: OwnProps
): IPropsFromState => {
  // Get league id from path
  const leagueNumber = parseInt(ownProps.leagueId!, 10) || 0;

  // Get phase and page numbers from query string
  const query = qs.parse(ownProps.location!.search, {
    ignoreQueryPrefix: true,
  });
  const phase = parseInt(query.phase as string, 10) || 1;
  const pageNewEntries = parseInt(query.page_new_entries as string, 10) || 1;
  const pageStandings = parseInt(query.page_standings as string, 10) || 1;

  return {
    now: getCurrentEvent(state),
    league: getClassicLeague(state, leagueNumber),
    leagueNumber,
    newEntries: getClassicNewEntries(state, leagueNumber, pageNewEntries),
    pageNewEntries,
    pageStandings,
    phase,
    phases: getStartedPhases(state),
    player: getPlayerData(state),
    standings: getClassicStandings(state, leagueNumber, phase, pageStandings),
    eventsById: getEventsById(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchStandings: (
    leagueId: number,
    phaseId: number,
    pageNewEntries: number,
    pageStandings: number
  ) =>
    dispatch(
      fetchClassicLeagueStandings(
        leagueId,
        pageNewEntries,
        pageStandings,
        phaseId
      )
    ),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(StandingsClassic)
);
