import { TFunction } from "i18next";

interface IStatDetails {
  description: string;
  label: string;
}

const allStatsDetails = (
  t: TFunction
): Record<string, IStatDetails | undefined> => ({
  total_points: {
    description: t(
      "stats.total_points.description",
      "Total fantasy points earned this season."
    ),
    label: t("stats.total_points.labeln", "Total fantasy points"),
  },
  event_points: {
    description: t(
      "stats.round_points.descriptionn",
      "Total fantasy points earned in the latest Gameday. "
    ),
    label: t("stats.round_points.labeln", "Gameday fantasy points"),
  },
  now_cost: {
    description: t(
      "stats.now_cost.descriptionn",
      "Cost of signing salary in transactions."
    ),
    label: t("stats.now_cost.labeln", "Salary"),
  },
  selected_by_percent: {
    description: t(
      "stats.selected_by_percent.descriptionn",
      "The percentage of overall Fantasy general managers who currently own the player."
    ),
    label: t("stats.selected_by_percent.label", "Team selected by %"),
  },
  minutes: {
    description: t(
      "stats.minutes.description",
      "Total minutes played this season."
    ),
    label: t("stats.minutes.label", "Minutes played"),
  },
  points_scored: {
    description: t(
      "stats.points_scored.description",
      "Total points scored this season."
    ),
    label: t("stats.points_scored.label", "Points scored"),
  },
  rebounds: {
    description: t(
      "stats.rebounds.descriptionn",
      "Total rebounds this season."
    ),
    label: t("stats.rebounds.label", "Rebounds"),
  },
  assists: {
    description: t("stats.assists.descriptionn", "Total assists this season."),
    label: t("stats.assists.label", "Assists"),
  },
  blocks: {
    description: t("stats.blocks.descriptionn", "Total blocks this season."),
    label: t("stats.blocks.label", "Blocks"),
  },
  steals: {
    description: t("stats.steals.descriptionn", "Total steals this season."),
    label: t("stats.steals.label", "Steals"),
  },
  turnovers: {
    description: t(
      "stats.turnovers.description",
      "Total turnovers this season."
    ),
    label: t("stats.turnovers.label", "Turnovers"),
  },
  form: {
    description: t(
      "stats.form.descriptionn",
      "A player’s average fantasy points per game, calculated from all games played in the last 30 days."
    ),
    label: t("stats.form.labeln", "Fantasy points per game (last 30)"),
  },
  // dreamteam_count: {
  //   description: t(
  //     "stats.dreamteam_count.description",
  //     "The number of times a player has been selected in a Team of the Week. Players with the most points in a Gameweek in a valid formation earn a place in the Team of the Week."
  //   ),
  //   label: t("stats.dreamteam_count.label", "Times in Team of the Week"),
  // },
  value_form: {
    description: t(
      "stats.value_form.descriptionn",
      "A player’s average points per game in the last 30 days divided by the player’s salary."
    ),
    label: t("stats.value_form.labeln", "Value (Avg. PPG last 30)"),
  },
  value_season: {
    description: t(
      "stats.value_season.descriptionn",
      "Player's total fantasy points divided by player’s salary."
    ),
    label: t("stats.value_season.labeln", "Value (fantasy season)"),
  },
  points_per_game: {
    description: t(
      "stats.points_per_game.descriptionn",
      "Player's total points divided by player's number of games played."
    ),
    label: t("stats.points_per_game.labeln", "Fantasy points per game"),
  },
  transfers_in: {
    description: t(
      "stats.transfers_in.descriptionn",
      "Total number of times a player has been signed this season in the transactions tab."
    ),
    label: t("stats.transfers_in.label", "Transactions in"),
  },
  transfers_out: {
    description: t(
      "stats.transfers_out.descriptionn",
      "Total number of times a player has waived in the Transactions tab."
    ),
    label: t("stats.transfers_out.label", "Transactions out"),
  },
  transfers_in_event: {
    description: t(
      "stats.transfers_in_event.description",
      "Total number of times a player has been transferred in to a team this Gameweek."
    ),
    label: t("stats.transfers_in_event.label", "Transactions in (Gameweek)"),
  },
  transfers_out_event: {
    description: t(
      "stats.transfers_out_event.description",
      "Total number of times a player has been transferred out of a team this Gameweek."
    ),
    label: t("stats.transfers_out_event.label", "Transactions out (Gameweek)"),
  },
  cost_change_start: {
    description: t(
      "stats.cost_change_start.descriptionn",
      "How much a player' salary has increased since the start of the season."
    ),
    label: t("stats.cost_change_start.labeln", "Salary increase"),
  },
  cost_change_start_fall: {
    description: t(
      "stats.cost_change_start_fall.descriptionn",
      "How much a player' salary has fallen since the start of the season."
    ),
    label: t("stats.cost_change_start_fall.labeln", "Salary drop"),
  },
  cost_change_event: {
    description: t(
      "stats.cost_change_event.descriptionn",
      "How much a player' salary has increased this Gameweek."
    ),
    label: t("stats.cost_change_event.labeln", "Salary increase (Gameweek)"),
  },
  cost_change_event_fall: {
    description: t(
      "stats.cost_change_event_fall.descriptionn",
      "How much a player' salary has fallen this Gameweek."
    ),
    label: t("stats.cost_change_event_fall.labeln", "Salary drop (Gameweek)"),
  },
});

let _stats: Record<string, IStatDetails | undefined> = {};

const getStats = (t: TFunction) => {
  if (!Object.keys(_stats).length) {
    _stats = allStatsDetails(t);
  }
  return _stats;
};

export const getStatDetails = (name: string, t: TFunction) => {
  const stats = getStats(t);
  return stats[name] ? stats[name] : null;
};

export const getStatNames = (t: TFunction) => Object.keys(getStats(t));
