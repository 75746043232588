import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Box } from "rebass/styled-components";
import styled, { css } from "styled-components/macro";
import BoldLinkButton from "../BoldLinkButton";
import Fixtures from "../Fixtures";
import { ControlArrowEnd } from "../icons/Arrows";
import { Main, Wrapper } from "../Layout";
import CreateSquad, { IListTableProps } from "./CreateSquad";
import ElementList from "./ElementList";
import { VisuallyHidden } from "../Utils";
import PageHeader from "../PageHeader";

const PusherWrap = styled.div`
  overflow-x: hidden;
`;

const Pusher = styled.div<IState>`
  position: relative;
  transition: all 300ms;

  ${(props) =>
    props.isSidebarVisible &&
    css`
      transform: translate3d(-100%, 0, 0);

      @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
        transform: none;
      }
    `}
`;

const PusherSecondary = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translate3d(100%, 0, 0);
  overflow-y: auto;
  padding-top: 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    position: static;
    transform: none;
    width: calc(100% / 3);
    padding-right: 1rem;
    padding-left: 1rem;
  }

  /* @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 25%;
  } */
`;

const ButtonWrap = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 33%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    display: none;
  }
`;

interface IProps {
  listTable: (listTableProps: IListTableProps) => void;
  scoreboard: string;
  submitDialog: (handleHide: () => void) => React.ReactNode;
  title: React.ReactNode;
  showListTableAsDefault?: boolean;
}

interface IState {
  isSidebarVisible: boolean;
}

type Props = IProps & WithTranslation;

class SquadBase extends React.Component<Props, IState> {
  public state: IState = {
    isSidebarVisible: false,
  };

  public showRef = React.createRef<HTMLButtonElement>();

  public hideRef = React.createRef<HTMLButtonElement>();

  public handleShowSidebar = () => this.setState({ isSidebarVisible: true });

  public handleHideSidebar = () => this.setState({ isSidebarVisible: false });

  public handleTransitionEnd = (e: React.TransitionEvent) => {
    // Just handle the Pusher transition
    if (e.target === e.currentTarget) {
      this.state.isSidebarVisible
        ? this.hideRef.current!.focus()
        : this.showRef.current!.focus();
    }
  };

  public render() {
    const { t } = this.props;
    return (
      <PusherWrap>
        <Pusher
          isSidebarVisible={this.state.isSidebarVisible}
          onTransitionEnd={this.handleTransitionEnd}
        >
          <Wrapper>
            <Main>
              <Box mx={2}>
                <VisuallyHidden as="div">
                  <PageHeader title={this.props.title} children={undefined} />
                </VisuallyHidden>
                <ButtonWrap>
                  <BoldLinkButton
                    ref={this.showRef}
                    onClick={this.handleShowSidebar}
                    label={t("squadBase.playerList", "Player List")}
                    icon={<ControlArrowEnd color="blueDark" />}
                  />
                </ButtonWrap>
              </Box>
              <CreateSquad
                showListTableAsDefault={this.props.showListTableAsDefault}
                listTable={this.props.listTable}
                scoreboard={this.props.scoreboard}
                showSidebar={this.handleShowSidebar}
                submitDialog={this.props.submitDialog}
              />
              <Fixtures />
            </Main>
            <PusherSecondary>
              <ElementList
                hideRef={this.hideRef}
                hideSidebar={this.handleHideSidebar}
              />
            </PusherSecondary>
          </Wrapper>
        </Pusher>
      </PusherWrap>
    );
  }
}

export default withTranslation()(SquadBase);
