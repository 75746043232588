import { useSelector } from "react-redux";
import styled, { css } from "styled-components/macro";
import { IElement } from "../core/store/elements/types";
import { getCurrentEvent, getEventsById } from "../core/store/events/reducers";
import { getFixturesByEvent } from "../core/store/fixtures/reducers";
import { getPhases } from "../core/store/phases/reducers";
import { getPrefixedEventShortName } from "../utils/events";
import Badge from "./Badge";
import Table from "./Table";
import { isRTL } from "../utils/locale";
import i18n from "../i18n";

interface IProps {
  element: IElement;
}

const Wrapper = styled.div`
  border: 1.5px solid ${(props) => props.theme.colors.pink};
  border-radius: ${(props) => props.theme.radii[1]};
  padding: ${(props) => props.theme.space[1]} 0;
`;

const HeaderRow = styled.tr`
  border-bottom: 1.5px solid ${(props) => props.theme.colors.pink};
`;

const StyledRow = styled.tr`
  border: 0;
`;

const GameweekDay = styled.td`
  position: relative;
  text-align: center;
  font-size: 1.2rem;
  &&& {
    padding: ${(props) => `${props.theme.space[2]} ${props.theme.space[1]}`};
  }

  ::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0.5rem;
    left: 0;
    top: 0;
    ${isRTL(i18n.language)
      ? css`
          border-left: 1.5px solid ${(props) => props.theme.colors.pink};
        `
      : css`
          border-right: 1.5px solid ${(props) => props.theme.colors.pink};
        `}
  }

  &:last-child::after {
    ${isRTL(i18n.language)
      ? css`
          border-left: 0;
        `
      : css`
          border-right: 0;
        `}
  }

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    font-size: ${(props) => props.theme.fontSizes[0]};
  }
`;

const FixtureField = styled.td`
  position: relative;
  text-align: center;

  ::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0.5rem;
    ${isRTL(i18n.language)
      ? css`
          border-left: 1.5px solid ${(props) => props.theme.colors.pink};
        `
      : css`
          border-right: 1.5px solid ${(props) => props.theme.colors.pink};
        `}
  }

  &:last-child::after {
    ${isRTL(i18n.language)
      ? css`
          border-left: 0;
        `
      : css`
          border-right: 0;
        `}
  }

  &&& {
    border-bottom: 0;
  }
`;

const renderBadge = (teamId: number) => {
  return <Badge teamId={teamId} width="40px" />;
};

const CurrentGameweekSchedule = ({ element }: IProps) => {
  // Get game days in a phase
  const eventsById = useSelector(getEventsById);
  const now = useSelector(getCurrentEvent);
  const activePhaseEvent = now ? now.id : 1;
  const activePhase = useSelector(getPhases).find(
    (p) =>
      p.id > 1 &&
      p.start_event <= activePhaseEvent &&
      p.stop_event >= activePhaseEvent
  );

  const gameDays = [];

  if (activePhase) {
    for (let i = activePhase.start_event; i <= activePhase.stop_event; i++) {
      gameDays.push([getPrefixedEventShortName(eventsById[i].name), i]);
    }
  }

  const fixturesByEvent = useSelector(getFixturesByEvent);
  const neededFixtures = gameDays.length;
  const elementFixtures: number[] = new Array(neededFixtures);
  elementFixtures.fill(0);

  const teamId = element.team;
  for (let i = 0; i < neededFixtures; i++) {
    const eventId = gameDays[i][1];
    if (fixturesByEvent[eventId]) {
      // No chance of a team having more than one fixture
      const fixture = fixturesByEvent[eventId].find(
        (f) => f.team_h === teamId || f.team_a === teamId
      );
      if (fixture) {
        elementFixtures[i] =
          teamId === fixture.team_h ? fixture.team_a : fixture.team_h;
      }
    }
  }

  return (
    <Wrapper>
      <Table>
        <thead>
          <HeaderRow>
            {gameDays.map((gameDay, index) => (
              <GameweekDay key={index}>{gameDay[0]}</GameweekDay>
            ))}
          </HeaderRow>
        </thead>

        <tbody>
          <StyledRow>
            {elementFixtures.map((opponentId) =>
              opponentId ? (
                <FixtureField>{renderBadge(opponentId)}</FixtureField>
              ) : (
                <FixtureField>-</FixtureField>
              )
            )}
          </StyledRow>
        </tbody>
      </Table>
    </Wrapper>
  );
};
export default CurrentGameweekSchedule;
