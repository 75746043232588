import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../core/store";
import { getActiveOrProposedTeamChipName } from "../../core/store/chips/reducers";
import { getElementTypesById } from "../../core/store/element-types/reducers";
import { getElement } from "../../core/store/elements/reducers";
import { getNextEvent } from "../../core/store/events/reducers";
import { getFixturesByEvent } from "../../core/store/fixtures/reducers";
import { IPickProposed } from "../../core/store/my-team/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { integerToDecimal } from "../../utils/decimal";
import { getNextElementFixtures } from "../../utils/fixtures";
import ElementDialogButton from "../ElementDialogButton";
import ElementInTable from "../ElementInTable";
import { ElementCell, ElementRow } from "../ElementTable";
import FixtureForElement from "../FixtureForElement";
import TeamElementIcon from "./TeamElementIcon";

interface IMyTeamRowProps {
  pick: IPickProposed;
  elementId: number;
  renderElementMenu: (index: number) => void;
}

const MyTeamRow: React.FC<IMyTeamRowProps> = ({
  pick,
  elementId,
  renderElementMenu,
}) => {
  const chipInPlayName = useSelector(getActiveOrProposedTeamChipName);
  const element = useSelector((state: RootState) =>
    getElement(state, elementId)
  );

  const fixturesByEvent = useSelector(getFixturesByEvent);
  const nextEvent = useSelector(getNextEvent);
  const neededFixtures = 3;
  const elementFixtures =
    element &&
    getNextElementFixtures(nextEvent, neededFixtures, fixturesByEvent, element);

  const elementTypesById = useSelector(getElementTypesById);
  const teamsById = useSelector(getTeamsById);

  if (!element) {
    return null;
  }

  return (
    <ElementRow variant={pick.subStatus}>
      <td>
        <ElementDialogButton elementId={element.id} variant="list" />
      </td>
      <td>
        <TeamElementIcon pick={pick} chipName={chipInPlayName} />
      </td>
      <ElementCell>
        <ElementInTable
          renderElementMenu={() => renderElementMenu(pick.element)}
          elementId={element.id}
        />
      </ElementCell>
      <td>{elementTypesById[element.element_type].singular_name_short}</td>
      <td>{integerToDecimal(Number(element.form), 10)}</td>
      <td>{integerToDecimal(element.event_points, 10)}</td>
      <td>{integerToDecimal(element.total_points, 10)}</td>
      {elementFixtures?.map((fixture) =>
        fixture.code ? (
          <td key={fixture.code}>
            <FixtureForElement
              element={element}
              fixture={fixture}
              teamsById={teamsById}
            />
          </td>
        ) : (
          <td key={fixture.event!}>{"-"}</td>
        )
      )}
    </ElementRow>
  );
};

export default MyTeamRow;
