import * as React from "react";
import styled from "styled-components/macro";

interface IStyledProps {
  ref?: any;
  variant?: "light";
}

interface IProps {
  icon: React.ReactNode;
  isBack?: boolean;
  label: string;
  onClick?: () => void;
}

type Props = IStyledProps & IProps;

const StyledBoldLinkButton = styled.button<IStyledProps>`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem ${(props) => props.theme.space[3]};
  border: 0;
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.yellow};
  color: ${(props) => props.theme.colors.offBlack};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  svg {
    fill: currentColor;
  }
`;

const BoldLinkButton: React.ForwardRefExoticComponent<Props> = React.forwardRef(
  ({ icon, isBack = false, label, ...rest }, ref) => (
    <StyledBoldLinkButton {...rest} ref={ref}>
      {isBack ? (
        <>
          <span>{icon}</span>
          <span>{label}</span>
        </>
      ) : (
        <>
          <span>{label}</span>
          <span>{icon}</span>
        </>
      )}
    </StyledBoldLinkButton>
  )
);

export default BoldLinkButton;
