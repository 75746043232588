import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";
import { IElement } from "../../core/store/elements/types";
import { ITeamsById } from "../../core/store/teams/types";
import Badge from "../Badge";
import BoldLink from "../BoldLink";
import ElementDialogButton from "../ElementDialogButton";
import { ElementRow, ElementTable, PrimaryCell } from "../ElementTable";
import Panel from "../Panel";
import { ScrollTable } from "../Table";
import StatusTableElement from "./StatusTableElement";

interface IProps {
  elements: IElement[];
  teamsById: ITeamsById;
  showLink: boolean;
  newsCount?: number;
  borderBottom: boolean;
}

const StyledThead = styled.thead`
  visibility: collapse;
`;

const InjuryTrackerTable = styled(ElementTable)`
  th:not(:nth-child(3)) {
    text-align: right;
  }
`;

const StyledScrollTable = styled(ScrollTable)`
  overflow-y: auto;
`;

const StatusCol = styled.th`
  width: 12%;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 10%;
  }
`;

const BadgeCol = styled.th`
  width: 5%;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 10%;
  }
`;

const PlayerCol = styled.th`
  width: 25%;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 35%;
  }
`;

const NewsCol = styled.th`
  width: 65%;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 50%;
  }
`;

const StyledPrimaryCell = styled(PrimaryCell)`
  text-align: start;
`;

export const ListItemStyles = css`
  list-style: none;
  text-align: start;
`;

const News = styled.span`
  display: inline-block;
  word-wrap: break-word;
  max-width: 60%;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    max-width: 100%;
  }
`;

const BoldLinkWrap = styled.div`
  text-align: end;
`;

const InjuryTracker = ({
  elements,
  teamsById,
  showLink,
  newsCount,
  borderBottom,
}: IProps) => {
  const { t } = useTranslation();

  let filteredNews = elements
    .filter((el) => el.news !== "")
    .slice(0, newsCount);

  return (
    <Panel>
      <Panel.Header
        upperCase={true}
        title={"Rotowire " + t("news.rotowire", "Injury Tracker")}
      ></Panel.Header>
      <Panel.Body>
        <StyledScrollTable>
          <InjuryTrackerTable>
            <StyledThead>
              <ElementRow>
                <StatusCol scope="col">&nbsp;</StatusCol>
                <BadgeCol scope="col">&nbsp;</BadgeCol>
                <PlayerCol scope="col">&nbsp;</PlayerCol>
                <NewsCol scope="col">&nbsp;</NewsCol>
              </ElementRow>
            </StyledThead>
            <tbody>
              {filteredNews.map((element) => {
                const team = teamsById[element.team];
                return (
                  <ElementRow key={element.id}>
                    <PrimaryCell>
                      <ElementDialogButton
                        elementId={element.id}
                        variant="list"
                      />
                    </PrimaryCell>
                    <PrimaryCell>
                      <Badge teamId={team.id} width="40px" />
                    </PrimaryCell>
                    <td>
                      <StatusTableElement
                        element={element}
                        teamsById={teamsById}
                      />
                    </td>
                    <StyledPrimaryCell>
                      <News>{element.news}</News>
                    </StyledPrimaryCell>
                  </ElementRow>
                );
              })}
            </tbody>
          </InjuryTrackerTable>
        </StyledScrollTable>
      </Panel.Body>
      <Panel.Footer borderBottom={borderBottom}>
        {showLink && (
          <BoldLinkWrap>
            <BoldLink to={`/player-news`} color="blueDark">
              {t("news.seeMore", "See more")}
            </BoldLink>
          </BoldLinkWrap>
        )}
      </Panel.Footer>
    </Panel>
  );
};

export default InjuryTracker;
