import { TFunction } from "i18next";
export type ChipName = "phcapt" | "rich" | "wildcard";

interface IChipDetails {
  description: string;
  name: string;
}

const allChipDetails = (t: TFunction): Record<ChipName, IChipDetails> => ({
  wildcard: {
    name: t("chips.wildcard.name", "Wildcard"),
    description: t(
      "chips.wildcard.description",
      "Make unlimited free transactions."
    ),
  },
  rich: {
    name: t("chips.allstar.name", "All-Star"),
    description: t(
      "chips.allstar.description",
      "Make unlimited free transactions with an unlimited budget for a single round. Next round your team will be set back to how it was before using the chip."
    ),
  },
  phcapt: {
    name: t("chips.phcapt.name", "Gameday Captain"),
    description: t(
      "chips.phcapt.description",
      "Pick a player to score double points."
    ),
  },
});

export const getChipName = (name: ChipName, t: TFunction) =>
  allChipDetails(t)[name].name;

export const getChipDetails = (name: ChipName, t: TFunction) =>
  allChipDetails(t)[name];

export const getAllChipDetails = (t: TFunction) => allChipDetails(t);
