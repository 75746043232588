import { ellipsis, hideVisually, rgba } from "polished";
import styled, { css } from "styled-components/macro";
import i18n from "../i18n";
import { isRTL } from "../utils/locale";

export const Ellipsis = styled.div`
  ${ellipsis()}
  ${isRTL(i18n.language) &&
  css`
    direction: rtl;
    unicode-bidi: plaintext;
  `}
`;

export const UnstyledButton = styled.button`
  display: block;
  width: 100%;
  padding: 0;
  border: 0;
  background-color: transparent;
  line-height: 1;
  cursor: pointer;
`;

export const VisuallyHidden = styled.span`
  ${hideVisually}
`;

export const PrimaryGradient = isRTL(i18n.language)
  ? css`
      background-image: linear-gradient(
        to left,
        ${(props) => props.theme.colors.blueDark} 75%,
        ${(props) => props.theme.colors.blue}
      );
    `
  : css`
      background-image: linear-gradient(
        to right,
        ${(props) => props.theme.colors.blueDark} 75%,
        ${(props) => props.theme.colors.blue}
      );
    `;

export const BoxWrapStyles = css`
  border-radius: ${(props) => props.theme.radii[1]};
  box-shadow: 1px 2px 10px 2px ${rgba("black", 0.1)};
`;

export const BoxWrap = styled.div`
  ${BoxWrapStyles}
`;

export const FixtureCellStyles = css`
  padding: ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii[1]};
  font-size: 1.2rem;
`;

export const FixtureCellPositionStyles = css`
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
  align-content: center;
  text-align: center;
`;
