import { rgba } from "polished";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";
import { IFixture } from "../../core/store/fixtures/types";
import { ITeamsById } from "../../core/store/teams/types";
import Badge from "../Badge";
import { DrawPrefix, LosePrefix, WinPrefix } from "../ScorePrefix";
import { FixtureCellStyles, FixtureCellPositionStyles } from "../Utils";

interface IProps {
  fixture?: IFixture;
  teamsById: ITeamsById;
  teamId: number;
}

interface IFixtureProps {
  isPlayed?: boolean;
  hasFixture?: boolean;
}

const FixtureWrap = styled.div<IFixtureProps>`
  ${FixtureCellPositionStyles}
  ${FixtureCellStyles}
  flex-direction: row-reverse;
  display: flex;
  justify-content: center;

  ${(props) =>
    props.isPlayed &&
    css`
      padding: 2px ${({ theme }) => theme.space[2]};
      border: ${(props) => `1px solid ${props.theme.colors.greyLight}`};
      border-radius: ${(props) => props.theme.radii[1]};
      background-color: ${(props) => props.theme.colors.white};
    `};

  ${(props) =>
    props.hasFixture &&
    !props.isPlayed &&
    css`
      background-color: ${(props) =>
        `${rgba(`${props.theme.colors["green"]}`, 0.15)}`};
    `}

  ${(props) =>
    !props.hasFixture &&
    css`
      background-color: ${(props) =>
        `${rgba(`${props.theme.colors["red"]}`, 0.15)}`};
    `}
`;

const FixtureName = styled.div<IFixtureProps>`
  display: flex;
  align-items: center;
  gap: 0.1rem;
  font-weight: 500;
`;

const FixtureCell = ({ fixture, teamId, teamsById }: IProps) => {
  const { t } = useTranslation();
  const hasFixture = !!fixture;

  if (!hasFixture) {
    return (
      <FixtureWrap hasFixture={hasFixture}>
        <FixtureName>-</FixtureName>
      </FixtureWrap>
    );
  }

  if (fixture) {
    const isHome = teamId === fixture.team_h;
    const team = isHome ? teamsById[fixture.team_a] : teamsById[fixture.team_h];
    const prefix = isHome
      ? t("fixtureCell.oppHome", "vs")
      : t("fixtureCell.oppAway", "@");
    const isPlayed =
      fixture.team_h_score !== null &&
      fixture.team_a_score !== null &&
      fixture.started;

    if (isPlayed)
      return (
        <FixtureWrap hasFixture={hasFixture} isPlayed={isPlayed}>
          <FixtureName isPlayed={isPlayed}>
            <span>{prefix}</span>
            <Badge teamId={team.id} width="30px" />
          </FixtureName>
          <FixtureName isPlayed={isPlayed}>
            {fixture.team_h_score! === fixture.team_a_score! ? (
              <DrawPrefix>{t("fixtureCell.draw", "D")}</DrawPrefix>
            ) : fixture.team_h_score! > fixture.team_a_score! && isHome ? (
              <WinPrefix>{t("fixtureCell.win", "W")}</WinPrefix>
            ) : fixture.team_a_score! > fixture.team_h_score! && !isHome ? (
              <WinPrefix>{t("fixtureCell.win", "W")}</WinPrefix>
            ) : (
              <LosePrefix>{t("fixtureCell.lose", "L")}</LosePrefix>
            )}
          </FixtureName>
        </FixtureWrap>
      );
    else
      return (
        <FixtureWrap hasFixture={hasFixture} isPlayed={isPlayed}>
          <FixtureName isPlayed={isPlayed}>
            <span>{prefix}</span>
            <Badge teamId={team.id} width="30px" />
          </FixtureName>
        </FixtureWrap>
      );
  }
  return null;
};

export default FixtureCell;
