import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components/macro";

interface IChipStatus {
  status: string;
  daysLeft: number;
}

const ChipGamedayCounter = styled.div<IChipStatus>`
  flex: 1 1 0;
  display: flex;
  gap: ${({ theme }) => theme.space[4]};
  align-self: stretch;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.space[5]} ${(props) => props.theme.space[2]};
  border-radius: ${(props) => props.theme.radii[0]};
  border: 2px solid #14c6ba;
  background-color: ${(props) =>
    props.status === "active" ? "#14c6ba" : "white"};
  font-weight: 600;
  text-transform: uppercase;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding: ${(props) => props.theme.space[3]};
    ${(props) => props.theme.space[2]};
  }
`;

const GamedayCounterString = styled.h3`
  text-align: left;
  text-transform: uppercase;
  width: min-content;
  margin: 0;
`;

const GamedayCounterNumber = styled.div`
  align-self: center;
  font-family: ${(theme) => theme.theme.fonts.action};
  font-style: italic;
  font-size: 4rem;
  font-weight: 600;
`;

const ChipStatus = ({ status, daysLeft }: IChipStatus) => {
  const { t } = useTranslation();
  if (status === "active") {
    return (
      <ChipGamedayCounter status={status} daysLeft={daysLeft}>
        {t("chipStatus.active", "active")}
      </ChipGamedayCounter>
    );
  }
  if (status === "played") {
    return (
      <ChipGamedayCounter status={status} daysLeft={daysLeft}>
        {t("chipStatus.unavailable", "unavailable until next GW")}
      </ChipGamedayCounter>
    );
  }
  if (daysLeft < 2) {
    return (
      <ChipGamedayCounter status={status} daysLeft={daysLeft}>
        <GamedayCounterNumber>
          {t("chipStatus.last", "Final Gameday to use")}
        </GamedayCounterNumber>
      </ChipGamedayCounter>
    );
  }
  return (
    <ChipGamedayCounter status={status} daysLeft={daysLeft}>
      <Trans i18nKey="chipStatus.remaining">
        <GamedayCounterString>Gamedays remaining</GamedayCounterString>
        <GamedayCounterNumber>{{ daysLeft }}</GamedayCounterNumber>
      </Trans>
    </ChipGamedayCounter>
  );
};

export default ChipStatus;
