import { RouteComponentProps } from "@reach/router";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Box } from "rebass";
import { ThunkDispatch } from "../core/store";
import { unsubscribeEntry } from "../core/store/entries/thunks";
import Button from "./Button";
import Copy from "./Copy";
import { Main, Wrapper } from "./Layout";
import Title from "./Title";

type OwnProps = RouteComponentProps<{ signature: string }>;

const EntryUnsubscribe: React.FC<OwnProps> = ({ signature }) => {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch<ThunkDispatch>();

  const [unsubscribeState, setUnsubscribeState] =
    React.useState<"pre" | "ok" | "bad">("pre");

  const doUnsubscribe = () => {
    reduxDispatch(unsubscribeEntry(signature!))
      .then(() => setUnsubscribeState("ok"))
      .catch(() => setUnsubscribeState("bad"));
  };

  const body = {
    pre: (
      <>
        <p>
          {t(
            "entryUnsubscribe.Explainn",
            "Unsubscribe from all emails about your NBA Fantasy team."
          )}
        </p>
        {
          <Button onClick={doUnsubscribe}>
            {t("entryUnsubscribe.button", "Unsubscribe")}
          </Button>
        }
      </>
    ),
    bad: (
      <p>
        {t(
          "entryUnsubscribe.bad",
          "There was a problem with the newsletter unsubscribe. Please try again or contact nbafantasy@ismgames.com."
        )}
      </p>
    ),
    ok: (
      <p>
        {t(
          "entryUnsubscribe.ok",
          "You have successfully unsubscribed from the newsletter."
        )}
      </p>
    ),
  };

  return (
    <Wrapper>
      <Main>
        <Box mx={2}>
          <Title>{t("entryUnsubscribe.Title", "Unsubscribe")}</Title>
        </Box>
        <Copy>{body[unsubscribeState]}</Copy>
      </Main>
    </Wrapper>
  );
};

export default EntryUnsubscribe;
