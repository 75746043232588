import { em } from "polished";
import styled, { css } from "styled-components/macro";

interface IProps {
  size?: string;
}

interface IScrollTable {
  $wide?: boolean;
}

export const ScrollTable = styled.div<IScrollTable>`
  width: 100%;
  @media (max-width: ${em("480px")}) {
    overflow-x: auto;

    table {
      width: 48rem;
    }
  }
  ${({ $wide }) =>
    $wide &&
    css`
      @media (max-width: ${em("700px")}) {
        overflow-x: auto;

        table {
          width: 80rem;
        }
      }
    `}
`;

const Table = styled.table<IProps>`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: ${({ theme }) => `${theme.space[2]}`};

    ${({ size }) =>
      size === "lg" &&
      css`
        padding: ${({ theme }) => `${theme.space[3]} ${theme.space[2]}`};
      `}
  }

  th {
    border-bottom: 1px solid #d8d8d8;
    font-weight: 300;
    text-align: start;
  }

  td {
    border-bottom: 1px solid #d8d8d8;
    font-weight: normal;
  }

  tr {
    background-color: white;

    &:nth-child(odd) {
      background-color: white;
    }
  }
`;

export default Table;
