import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Flex } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import {
  cancelProposedChip,
  proposeAvailableChip,
} from "../../core/store/chips/actions";
import {
  getActiveChipName,
  getAvailableChipNames,
  getPotentialChips,
  getProposedChipName,
} from "../../core/store/chips/reducers";
import { IPotentialChip } from "../../core/store/chips/types";
import { getElementTypesBySquadPosition } from "../../core/store/element-types/reducers";
import { IElementTypesBySquadPosition } from "../../core/store/element-types/types";
import {
  canAutocomplete,
  canReset,
  getErrors,
  getFreeTransfersRemaining,
  getProposedElements,
  getToSpend,
  getTransferCosts,
} from "../../core/store/squad/reducers";
import { autoComplete, reset } from "../../core/store/squad/thunks";
import { IProposedElements, ISquadErrors } from "../../core/store/squad/types";
import { integerToMoney } from "../../core/utils/money";
import { ChipName, getChipName } from "../../utils/chips";
import { integerToDecimal } from "../../utils/decimal";
import Alert from "../Alert";
import Button from "../Button";
import Dialog from "../Dialog";
import DialogManager from "../DialogManager";
import { BoxWrapStyles, PrimaryGradient } from "../Utils";
import { ScoreboardPanel } from "./ScoreboardPanel";

const StyledBasicScoreboard = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${(props) => props.theme.space[4]};
`;

const ScoreboardButtons = styled.div`
  flex: 0 0 100%;
  margin-bottom: ${(props) => props.theme.space[2]};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex-basis: 33.3%;
    margin-bottom: 0;
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  gap: ${(props) => props.theme.space[4]};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex-direction: column;
  }
`;

const ScoreboardPanelWrap = styled.div`
  flex: 0 0 calc(50% - ${(props) => props.theme.space[1]});

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex-basis: calc(33.3% - ${(props) => props.theme.space[2]});
    display: flex;
    flex-direction: column;
  }
`;

export const ScoreboardBox = styled.div`
  ${BoxWrapStyles}
  position: relative;
  flex: 1;
  border: 1px solid ${(props) => props.theme.colors.blueDark};
  padding: ${(props) =>
    `${props.theme.space[2]} ${props.theme.space[2]} ${props.theme.space[3]}`};
  text-align: center;

  ::after {
    ${PrimaryGradient}
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: ${(props) => props.theme.space[1]};
    border-bottom-right-radius: ${(props) => props.theme.radii[1]};
    border-bottom-left-radius: ${(props) => props.theme.radii[1]};
  }
`;

const CostScoreboardUnit = styled.div`
  flex: 1 0 50%;
  padding: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[2]};

  :nth-child(4) > div {
    border-bottom-width: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex: 1 0 25%;

    :nth-child(3) > div {
      border-bottom-width: 0;
    }

    :nth-child(4) > div {
      border-bottom-width: 1px;
    }
  }
`;

interface IBasicProps {
  autoPick: () => void;
  canAutocomplete: boolean;
  canReset: boolean;
  currencyDivisor: number;
  elementTypesByPosition: IElementTypesBySquadPosition;
  isBudgetExceeded: boolean;
  isNeedElements: boolean;
  proposedElements: IProposedElements;
  reset: () => void;
  toSpend: number;
}

const Basic: React.FC<IBasicProps> = ({
  autoPick,
  canAutocomplete,
  canReset,
  currencyDivisor,
  elementTypesByPosition,
  isBudgetExceeded,
  isNeedElements,
  proposedElements,
  reset,
  toSpend,
}) => {
  const { t } = useTranslation();
  return (
    <StyledBasicScoreboard>
      <ScoreboardButtons>
        <ScoreboardBox>
          <ButtonWrap>
            <Button
              onClick={autoPick}
              fullwidth="true"
              disabled={!canAutocomplete}
            >
              {t("scoreboard.autoPick", "Auto Pick")}
            </Button>
            <Button
              type="reset"
              onClick={reset}
              variant="tertiary"
              fullwidth="true"
              disabled={!canReset}
            >
              {t("reset", "Reset")}
            </Button>
          </ButtonWrap>
        </ScoreboardBox>
      </ScoreboardButtons>
      <ScoreboardPanelWrap>
        <ScoreboardPanel
          heading={t("scoreboard.playersSelected", "Players Selected")}
          value={`${Object.keys(proposedElements).length} / ${
            Object.keys(elementTypesByPosition).length
          }`}
          isError={isNeedElements}
          isSuccess={!isNeedElements}
        />
      </ScoreboardPanelWrap>
      <ScoreboardPanelWrap>
        <ScoreboardPanel
          heading={t("scoreboard.moneyRemaining", "Money Remaining")}
          value={`${integerToMoney(toSpend, currencyDivisor)}`}
          isError={isBudgetExceeded}
          isSuccess={!isBudgetExceeded}
        />
      </ScoreboardPanelWrap>
    </StyledBasicScoreboard>
  );
};

interface IExplainChipDialogProps {
  chip: string;
  handleHide: () => void;
  transferCosts: number;
}

const ExplainChipDialog: React.FC<IExplainChipDialogProps> = ({
  chip,
  handleHide,
  transferCosts,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog closeDialog={handleHide}>
      <Dialog.Header closeDialog={handleHide}>
        {chip === "wildcard" && t("squad.playWildcardText", "Play Wildcard")}
        {chip === "rich" && t("squad.playAllStarText", "Play All-Star")}
      </Dialog.Header>
      <Dialog.Body isPadded={true}>
        <Alert type="info">
          {transferCosts ? (
            <>
              <p>
                {chip === "wildcard" &&
                  t(
                    "squad.wildcardText1",
                    "You are now proposing to play your wildcard. Unlimited transactions can be made this Gameday without points deduction."
                  )}
                {chip === "rich" &&
                  t(
                    "squad.allStarext1",
                    "You are now proposing to play your All-Star chip. Unlimited transactions with an unlimted budget can be made this Gameday without points deduction."
                  )}
              </p>
              <p>
                {chip === "wildcard" &&
                  t(
                    "squad.wildcardText2",
                    "Your wildcard will not be activated until you confirm your transactions."
                  )}
                {chip === "rich" &&
                  t(
                    "squad.allStarText2",
                    "Your All-Star will not be activated until you confirm your transactions"
                  )}
              </p>
            </>
          ) : (
            <p>
              {chip === "wildcard" &&
                t(
                  "squad.wildcardText3",
                  "To activate your wildcard you must be making enough proposed transactions that points will be deducted."
                )}
              {chip === "rich" &&
                t(
                  "squad.allStarText3",
                  "To activate your All-Star chip you must be making enough proposed transactions that points will be deducted."
                )}
            </p>
          )}
        </Alert>
      </Dialog.Body>
    </Dialog>
  );
};

interface ICostProps {
  activeChipName: string;
  availableChipNames: string[];
  autoPick: () => void;
  canAutocomplete: boolean;
  canReset: boolean;
  currencyDivisor: number;
  freeTransfersRemaining: number;
  isBudgetExceeded: boolean;
  proposedChipName: string;
  reset: () => void;
  toSpend: number;
  transferCosts: number;
  cancelChip: (chip: string) => void;
  proposeChip: (chip: string) => void;
  chips: IPotentialChip[];
}

const Cost: React.FC<ICostProps> = ({
  activeChipName,
  availableChipNames,
  autoPick,
  canAutocomplete,
  canReset,
  currencyDivisor,
  freeTransfersRemaining,
  isBudgetExceeded,
  proposedChipName,
  reset,
  toSpend,
  transferCosts,
  cancelChip,
  proposeChip,
  chips,
}) => {
  const { t } = useTranslation();
  const wildCardChip = chips.filter((c) => c.name === "wildcard")[0];
  const AllStarChip = chips.filter((c) => c.name === "rich")[0];
  return (
    <>
      <Flex flexWrap="wrap" alignItems="center" mb={2}>
        <CostScoreboardUnit>
          <Button
            disabled={!canAutocomplete}
            onClick={autoPick}
            fullwidth="true"
          >
            {t("scoreboard.cost.autoPick", "Auto Pick")}
          </Button>
        </CostScoreboardUnit>
        <CostScoreboardUnit>
          <Button
            disabled={!canReset}
            onClick={reset}
            type="reset"
            variant="tertiary"
            fullwidth="true"
          >
            {t("scoreboard.cost.reset", "Reset")}
          </Button>
        </CostScoreboardUnit>

        {activeChipName ? (
          <CostScoreboardUnit>
            <ScoreboardPanel
              heading={t("scoreboard.chip.active", "Active Chip")}
              value={getChipName(activeChipName as ChipName, t)}
            />
          </CostScoreboardUnit>
        ) : wildCardChip.status_for_entry === "played" ? (
          <CostScoreboardUnit>
            <ScoreboardPanel
              heading={t("scoreboard.wildcard.wildcard", "Wildcard")}
              value={t("scoreboard.wildcard.played", "Played")}
            />
          </CostScoreboardUnit>
        ) : wildCardChip.status_for_entry === "active" ? (
          <CostScoreboardUnit>
            <ScoreboardPanel
              heading={t("scoreboard.wildcard.wildcard", "Wildcard")}
              value={t("scoreboard.wildcard.active", "Active")}
            />
          </CostScoreboardUnit>
        ) : proposedChipName === "wildcard" ? (
          <CostScoreboardUnit>
            <Button onClick={() => cancelChip("wildcard")}>
              {t("squad.cancelWildcardText", "Cancel Wildcard")}
            </Button>
          </CostScoreboardUnit>
        ) : (
          <DialogManager
            render={(showDialog, handleShow, handleHide) => (
              <>
                <CostScoreboardUnit>
                  <Button
                    disabled={
                      proposedChipName === "rich" || activeChipName === "rich"
                    }
                    onClick={handleShow}
                    fullwidth="true"
                  >
                    {t("squad.playWildcardTest", "Play Wildcard")}
                  </Button>
                </CostScoreboardUnit>
                {showDialog && (
                  <ExplainChipDialog
                    chip="wildcard"
                    handleHide={() => {
                      handleHide();
                      if (transferCosts) {
                        proposeChip("wildcard");
                      }
                    }}
                    transferCosts={transferCosts}
                  />
                )}
              </>
            )}
          />
        )}
        {activeChipName ? (
          <>&nbsp;</>
        ) : AllStarChip.status_for_entry === "played" ? (
          <CostScoreboardUnit>
            <ScoreboardPanel heading="All-Star" value={"Played"} />
          </CostScoreboardUnit>
        ) : AllStarChip.status_for_entry === "active" ? (
          <CostScoreboardUnit>
            <ScoreboardPanel heading="All-Star" value={"Active"} />
          </CostScoreboardUnit>
        ) : proposedChipName === "rich" ? (
          <CostScoreboardUnit>
            <Button onClick={() => cancelChip("rich")}>
              {t("squad.cancelAllStarText", "Cancel All-Star")}
            </Button>
          </CostScoreboardUnit>
        ) : (
          <DialogManager
            render={(showDialog, handleShow, handleHide) => (
              <>
                <CostScoreboardUnit>
                  <Button
                    disabled={
                      proposedChipName === "wildcard" ||
                      activeChipName === "wildcard"
                    }
                    onClick={handleShow}
                    fullwidth="true"
                  >
                    {t("squad.playAllStarText", "Play All-Star")}
                  </Button>
                </CostScoreboardUnit>
                {showDialog && (
                  <ExplainChipDialog
                    chip="rich"
                    handleHide={() => {
                      handleHide();
                      if (transferCosts) {
                        proposeChip("rich");
                      }
                    }}
                    transferCosts={transferCosts}
                  />
                )}
              </>
            )}
          />
        )}
      </Flex>
      <Flex flexWrap="wrap" alignItems="center" mb={2}>
        <CostScoreboardUnit>
          <ScoreboardPanel
            heading={t("squad.freeTransfers", "Free Transactions")}
            value={
              activeChipName === "wildcard" || activeChipName === "rich"
                ? t("squad.unlimited", "Unlimited")
                : `${freeTransfersRemaining}`
            }
          />
        </CostScoreboardUnit>
        <CostScoreboardUnit>
          <ScoreboardPanel
            heading={t("squad.cost", "Cost")}
            value={`${integerToDecimal(transferCosts, 10)} pts`}
            isError={transferCosts > 0}
          />
        </CostScoreboardUnit>
        <CostScoreboardUnit>
          <ScoreboardPanel
            heading={t("squad.moneyRemaining", "Money Remaining")}
            value={
              proposedChipName === "rich" || activeChipName === "rich"
                ? `--`
                : `${integerToMoney(toSpend, currencyDivisor)}`
            }
            isError={isBudgetExceeded}
            isSuccess={!isBudgetExceeded}
          />
        </CostScoreboardUnit>
      </Flex>
    </>
  );
};

interface IOwnProps {
  scoreboard: string;
}

interface IPropsFromState {
  activeChipName: string;
  availableChipNames: string[];
  canAutocomplete: boolean;
  canReset: boolean;
  currencyDivisor: number;
  elementTypesByPosition: IElementTypesBySquadPosition | null;
  errors: ISquadErrors;
  freeTransfersRemaining: number;
  proposedChipName: string;
  proposedElements: IProposedElements;
  toSpend: number;
  transferCosts: number;
  chips: IPotentialChip[];
}

interface IPropsFromDispatch {
  autoPick: () => void;
  reset: () => void;
  cancelChip: (chip: string) => void;
  proposeChip: (chip: string) => void;
}

type Props = IOwnProps & IPropsFromState & IPropsFromDispatch;

class Scoreboard extends React.Component<Props> {
  public render() {
    const {
      elementTypesByPosition,
      errors,
      proposedElements,
      scoreboard,
      ...rest
    } = this.props;
    if (!elementTypesByPosition) {
      return null;
    }
    const isNeedElements = Boolean(errors.needElements);
    const isBudgetExceeded = Boolean(errors.budgetExceeded);
    switch (scoreboard) {
      case "basic":
        return (
          <Basic
            {...rest}
            elementTypesByPosition={elementTypesByPosition}
            isNeedElements={isNeedElements}
            isBudgetExceeded={isBudgetExceeded}
            proposedElements={proposedElements}
          />
        );
      case "cost":
        return <Cost {...rest} isBudgetExceeded={isBudgetExceeded} />;
      default:
        return null;
    }
  }
}

const mapStateToProps = (state: RootState): IPropsFromState => ({
  activeChipName: getActiveChipName(state),
  availableChipNames: getAvailableChipNames(state),
  canAutocomplete: canAutocomplete(state),
  canReset: canReset(state),
  currencyDivisor: 10,
  elementTypesByPosition: getElementTypesBySquadPosition(state),
  errors: getErrors(state),
  freeTransfersRemaining: getFreeTransfersRemaining(state),
  proposedChipName: getProposedChipName(state),
  proposedElements: getProposedElements(state),
  toSpend: getToSpend(state),
  transferCosts: getTransferCosts(state),
  chips: getPotentialChips(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  autoPick: () => {
    if (!dispatch(autoComplete())) {
      // We should do something :-)
      window.console.log("Failed to autocomplete");
    }
  },
  cancelChip: (chip: string) => dispatch(cancelProposedChip(chip)),
  proposeChip: (chip: string) => dispatch(proposeAvailableChip(chip)),
  reset: () => dispatch(reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Scoreboard);
