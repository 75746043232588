import * as Sentry from "@sentry/browser";
import { hiDPI, size } from "polished";
import * as React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled, { ThemeProvider, css } from "styled-components/macro";
import GlobalStyle from "../GlobalStyle";
import useScript from "../core/hooks/useScript";
import { RootState, ThunkDispatch } from "../core/store";
import { getBootstrapped } from "../core/store/bootstrap/reducers";
import { bootstrap } from "../core/store/bootstrap/thunks";
import { getServerError } from "../core/store/global/reducers";
import { IServerError } from "../core/store/global/types";
import "../fonts.css";
import i18n from "../i18n";
import { ReactComponent as BaseFantasyLogo } from "../img/fantasy-logo.svg";
import Background from "../img/headerbackground@1.5x.png";
import Background2x from "../img/headerbackground@2x.png";
// import { ReactComponent as PaniniSVG } from "../img/panini-logo.svg";
import playerComp175 from "../img/player-comp-175.png";
import playerComp345 from "../img/player-comp-345.png";
import playerComp460 from "../img/player-comp-460.png";
import playerComp690 from "../img/player-comp-690.png";
import theme from "../theme";
import { isRTL } from "../utils/locale";
import { SITE_IDS, getSite } from "../utils/sites";
import ElementDialog from "./ElementDialog";
import FixturesLoader from "./FixturesLoader";
import Footer from "./Footer";
import { Main, Wrapper } from "./Layout";
import ServerError from "./ServerError";
import { PrimaryGradient, VisuallyHidden } from "./Utils";
import AutoJoinDialog from "./leagues/AutoJoinDialog";
import Navigation from "./nav/Navigation";
const Sport5 = React.lazy(() => import("./sites/sport5"));
const SkyItaliaHeader = React.lazy(
  () => import("./sites/skyItalia/SkyItaliaHeader")
);
const SkyItaliaFooter = React.lazy(
  () => import("./sites/skyItalia/SkyItaliaFooter")
);

interface IPropsHeaderWrapper {
  site: string;
}

const headerStyles = {
  euro: css`
    padding-top: 53px;

    @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
      padding-top: 70px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
      padding-top: 163px;
    }
  `,
};

const GameHeaderOuter = styled.header<ILocaleStyles>`
  position: relative;
  background-color: ${(props) => props.theme.colors.white};
  filter: drop-shadow(0px 0px 4px ${(props) => props.theme.colors.greyLight});

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    background-image: url(${Background});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    ${(props) =>
      props.rtl &&
      css`
        background-position: left;
      `}

    ${hiDPI(2)} {
      background-image: url(${Background2x});
    }
  }
`;

const BorderTop = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 30px;
  background-image: linear-gradient(
    to bottom,
    ${(props) => props.theme.colors.red} 10%,
    ${(props) => props.theme.colors.pink}
  );
`;

const BorderBottom = styled.div`
  ${PrimaryGradient}
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 55px;
`;

const GameHeaderInner = styled.div<ILocaleStyles>`
  position: relative;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 ${(props) => props.theme.space[2]};
  background-image: url(${playerComp175});
  background-size: 180px auto;
  background-repeat: no-repeat;
  /* 
  TODO: #4399 panini-logo styling 
  background-position: right 35px bottom 55px; 
  */
  background-position: right 0px bottom 55px;
  ${(props) =>
    props.rtl &&
    css`
      /* TODO: #4399 panini-logo styling 
      background-position: left 35px bottom 55px; 
      */
      background-position: left 0px bottom 55px;
    `}

  ${hiDPI(2)} {
    background-image: url(${playerComp345});
  }

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    background-position: right 100px bottom 55px;
    background-size: 200px auto;
    ${(props) =>
      props.rtl &&
      css`
        background-position: left 100px bottom 55px;
      `}
  }

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    padding: 0 ${(props) => props.theme.space[4]};
    background-image: url(${playerComp460});
    background-position: right 150px bottom 0px;
    background-size: 370px auto;
    ${(props) =>
      props.rtl &&
      css`
        background-position: left 150px bottom 0px;
      `}

    ${hiDPI(2)} {
      background-image: url(${playerComp690});
    }
  }
`;

const GameTitle = styled.h1`
  margin: 0;
  padding: 3.5rem 0 6.5rem;

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    padding: 2.5rem 0 6.5rem;
  }
`;

// const PaniniLogo = styled(PaniniSVG)<ILocaleStyles>`
//   display: block;
//   position: absolute;
//   ${size(25, 60)}
//   bottom: 7.5rem;
//   ${(props) =>
//     props.rtl
//       ? css`
//           left: 0.5rem;
//         `
//       : css`
//           right: 0.5rem;
//         `}

//   @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
//     ${size(30, 80)}
//     ${(props) =>
//       props.rtl
//         ? css`
//             left: 2rem;
//           `
//         : css`
//             right: 2rem;
//           `}
//   }
//   @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
//     ${size(50, 110)}
//   }
// `;

const FantasyLogo = styled(BaseFantasyLogo)`
  ${size(75, 180)}

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    ${size(150, 400)}
  }
`;

const GameHeaderWrapper = styled.div`
  position: relative;
  z-index: 2;
`;

interface ILocaleStyles {
  rtl: boolean;
}

interface IProps {
  bootstrapped: boolean;
  serverError: IServerError | null;
}

export const Loading: React.FC = () => (
  <Wrapper>
    <Main>
      <Box mx={2}>{i18n.t("app.loading", "Loading ...")}</Box>
    </Main>
  </Wrapper>
);

const StyledHeaderWrapper = styled.div<IPropsHeaderWrapper>`
  ${({ site }) => site === "euro" && headerStyles.euro};
`;

const StyledFooterWrapper = styled.div``;

const App: React.FC<IProps> = ({ bootstrapped, serverError }) => {
  const site = getSite();

  const siteHeaders: Record<SITE_IDS, React.ReactNode> = {
    main_us: null,
    main_en: null,
    main_fr: null,
    main_es: null,
    main_it: null,
    main_de: null,
    main_el: null,
    main_he: null,
    main_br: null,
    main_tr: null,
    main_ar: null,
    sport5: <Sport5 />,
    sky_italia: <SkyItaliaHeader />,
  };
  const siteFooters: Record<SITE_IDS, React.ReactNode> = {
    main_us: null,
    main_en: null,
    main_fr: null,
    main_es: null,
    main_it: null,
    main_de: null,
    main_el: null,
    main_he: null,
    main_br: null,
    main_tr: null,
    sport5: null,
    main_ar: null,
    sky_italia: <SkyItaliaFooter />,
  };
  useScript(process.env.REACT_APP_WIDGET_SRC);
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <React.Suspense fallback={<div />}>
          <Helmet>
            <html
              lang={i18n.language}
              dir={isRTL(i18n.language) ? "rtl" : "ltr"}
            />
            <title>{i18n.t("app.gameName", "NBA Fantasy")}</title>
            <meta
              name="description"
              content={i18n.t(
                "app.description1n",
                "The new NBA Fantasy game for the 2023/24 season. Build your dream roster of NBA players. Play with your friends and fans from around the world to be the NBA Fantasy Champion."
              )}
            />
          </Helmet>
          <StyledHeaderWrapper site={site}>
            {siteHeaders[site]}
          </StyledHeaderWrapper>
          <GlobalStyle />
          <GameHeaderWrapper>
            <GameHeaderOuter rtl={isRTL(i18n.language)}>
              <BorderTop />
              <BorderBottom />
              <GameHeaderInner rtl={isRTL(i18n.language)}>
                <GameTitle>
                  <FantasyLogo />
                  <VisuallyHidden>
                    {i18n.t("app.logo", "NBA Fantasy")}
                  </VisuallyHidden>
                </GameTitle>
                {/* <PaniniLogo rtl={isRTL(i18n.language)} /> */}
                <Navigation />
              </GameHeaderInner>
            </GameHeaderOuter>
          </GameHeaderWrapper>
          {serverError ? (
            <ServerError error={serverError} />
          ) : bootstrapped ? (
            <FixturesLoader />
          ) : (
            <Loading />
          )}
          <Footer />
          <StyledFooterWrapper>{siteFooters[site]}</StyledFooterWrapper>

          <AutoJoinDialog />
          <ElementDialog />
        </React.Suspense>
      </ThemeProvider>
    </React.StrictMode>
  );
};

interface IContainerPropsFromState {
  bootstrapped: boolean;
  serverError: IServerError | null;
}

interface IState {
  eventId: string | null;
  error: Error | null;
}

interface IContainerPropsFromDispatch {
  bootstrapApp: () => Promise<void>;
}
type ContainerProps = IContainerPropsFromState & IContainerPropsFromDispatch;
class AppContainer extends React.Component<ContainerProps, IState> {
  public state: IState = { error: null, eventId: null };
  public componentDidMount() {
    this.props.bootstrapApp();
  }

  public componentDidCatch(error: Error | null, errorInfo: {}) {
    this.setState({ error });
    if (process.env.REACT_APP_SENTRY_ENVIRONMENT) {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  public render() {
    // JS Error
    if (this.state.error) {
      // Can't reliably use any of our components, eg Button
      return (
        <>
          <h2>{i18n.t("app.error", "Error")}</h2>
          <p>{i18n.t("app.wrong", "We're sorry - something's gone wrong.")}</p>
          {process.env.REACT_APP_SENTRY_ENVIRONMENT && (
            <>
              <p>
                {i18n.t(
                  "app.notified",
                  "Our team has been notified, but click here to fill out a report."
                )}
              </p>
              <button
                onClick={() =>
                  Sentry.showReportDialog({
                    eventId: this.state.eventId as string,
                  })
                }
              >
                {i18n.t("app.report", "Report feedback")}
              </button>
            </>
          )}
        </>
      );
    }
    // All OK - Render App
    return (
      <App
        bootstrapped={this.props.bootstrapped}
        serverError={this.props.serverError}
      />
    );
  }
}

const mapStateToProps = (state: RootState): IContainerPropsFromState => ({
  bootstrapped: getBootstrapped(state),
  serverError: getServerError(state),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch
): IContainerPropsFromDispatch => ({
  bootstrapApp: () => dispatch(bootstrap()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
