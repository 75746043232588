import { RouteComponentProps } from "@reach/router";
import React from "react";
import { connect } from "react-redux";
import { RootState, ThunkDispatch } from "../../core/store";
import { fetchMyTeam } from "../../core/store/my-team/thunks";
import { ITransferState } from "../../core/store/my-team/types";
import { getTransferState } from "../../core/store/squad/reducers";
import ConfirmTransfersDialog from "./ConfirmTransfersDialog";
import SquadBase from "./SquadBase";
import TransfersListTable from "./TransfersListTable";
import { withTranslation, WithTranslation } from "react-i18next";

interface IPropsFromState {
  transfersState: ITransferState | null;
}

interface IPropsFromDispatch {
  fetchMyTeam: () => void;
}

type Props = RouteComponentProps &
  IPropsFromState &
  IPropsFromDispatch &
  WithTranslation;

class Transfers extends React.Component<Props> {
  public componentDidMount() {
    this.props.fetchMyTeam();
  }

  public render() {
    // Don't render until we know what transfers staate we need to render ...
    if (!this.props.transfersState) {
      return null;
    }
    const scoreboard =
      this.props.transfersState.status === "cost" ? "cost" : "basic";
    const { t } = this.props;
    return (
      <SquadBase
        showListTableAsDefault={true}
        listTable={(listTableProps) => (
          <TransfersListTable {...listTableProps} />
        )}
        scoreboard={scoreboard}
        submitDialog={(handleHide) => (
          <ConfirmTransfersDialog handleHide={handleHide} />
        )}
        title={t("transfers.title", "Transactions")}
      />
    );
  }
}

export { Transfers as TransfersTest };

const mapStateToProps = (state: RootState): IPropsFromState => ({
  transfersState: getTransferState(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchMyTeam: () => dispatch(fetchMyTeam()),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Transfers)
);
