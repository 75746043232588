import { size } from "polished";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../core/store";
import { getElementStats } from "../core/store/element-stats/reducers";
import { IElementStat } from "../core/store/element-stats/types";
import { getElementTypesById } from "../core/store/element-types/reducers";
import { IElementTypesById } from "../core/store/element-types/types";
import { elementDialogHide } from "../core/store/elements/actions";
import {
  getElement,
  getElementDialog,
  getFixturesWithBlanks,
  getHistory,
  getHistoryTotals,
  getSeasonHistory,
} from "../core/store/elements/reducers";
import {
  IElement,
  IElementFixture,
  IElementFixtureBlank,
  IElementHistory,
  IElementSeasonHistory,
} from "../core/store/elements/types";
import {
  getCurrentEvent,
  getEvents,
  getEventsById,
  getNextEvent,
} from "../core/store/events/reducers";
import { IEvent, IEventsById } from "../core/store/events/types";
import { getPlayerData, getWatched } from "../core/store/player/reducers";
import {
  addToWatchlist,
  removeFromWatchlist,
} from "../core/store/player/thunks";
import { IPlayer } from "../core/store/player/types";
import { proposeElement } from "../core/store/squad/thunks";
import { getTeamsById } from "../core/store/teams/reducers";
import { ITeamsById } from "../core/store/teams/types";
import { formatRawAsLocalI18n } from "../core/utils/datetime";
import { integerToMoney } from "../core/utils/money";
import { dateLocales } from "../i18n";
import { ReactComponent as BaseHeadlineLogo } from "../img/headline-logo.svg";
import { integerToDecimal } from "../utils/decimal";
import { getPrefixedEventShortName } from "../utils/events";
import Alert from "./Alert";
import Badge from "./Badge";
import Button from "./Button";
import CurrentGameweekSchedule from "./CurrentGameweekSchedule";
import Dialog, { CloseButton, CloseIcon } from "./Dialog";
import FixtureForElement from "./FixtureForElement";
import "./OverflowScrolling.css";
import Shirt from "./Shirt";
import SubHeading from "./SubHeading";
import Table from "./Table";
import TabPanel from "./tabs/TabPanel";
import Tabs from "./tabs/Tabs";
import Tooltip, { TooltipLabel } from "./Tooltip";
import { VisuallyHidden } from "./Utils";

const ElementHeader = styled.div`
  display: flex;
  align-items: center;
  margin: ${(props) => props.theme.space[2]} 0;
  padding: 0 ${(props) => props.theme.space[2]};
`;

const ElementLogo = styled.div`
  flex: 1;
`;

const StyledHeadlineLogo = styled(BaseHeadlineLogo)`
  ${size(42, 70)}
`;

const Summary = styled.div<{ elementTypeId: number }>`
  margin-bottom: ${(props) => props.theme.space[2]};
  padding: 0.7rem 0;
`;

const SummaryInner = styled.div<{ teamCode: number }>`
  position: relative;
  display: flex;
  justify-content: space-around;
  background-color: ${(props) => props.theme.colors.teams[props.teamCode].dark};
  border-radius: ${(props) => props.theme.radii[0]};
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fontSizes[3]};
  overflow: hidden;

  ::after {
    ${size(250)}
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-image: ${(props) =>
      props.teamCode
        ? `url(//cdn.nba.com/logos/nba/${props.teamCode}/global/D/logo.svg)`
        : "none"};
    background-repeat: no-repeat;
    background-size: contain;
    background-position: -90px -55px;
    opacity: 0.25;

    @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
      ${size(310)}
      background-position: -105px -70px;
    }
  }
`;
const SummaryItem = styled.div`
  position: relative;
  z-index: 100;
  flex: 0 0 calc(50% - (${(props) => props.theme.space[3]} * 2));
  align-self: center;
`;

const Headshot = styled.div`
  padding-top: ${(props) => props.theme.space[1]};
  width: 148px;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 190px;
  }
`;

const SummaryInfo = styled.div`
  padding: ${(props) => props.theme.space[1]} 0;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    padding-top: ${(props) => props.theme.space[2]};
    padding-bottom: ${(props) => props.theme.space[2]};
  }
`;

const SummaryName = styled.h2`
  margin: 0 0 ${(props) => props.theme.space[2]};
  font-size: 2rem;
  font-weight: 500;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    margin-bottom: ${(props) => props.theme.space[4]};
  }
`;

const SummaryType = styled.div`
  font-weight: 300;
  font-style: italic;
`;

const StatList = styled.ul`
  display: flex;
  gap: ${({ theme }) => theme.space[1]};
  margin: 0 0 ${({ theme }) => theme.space[2]};
  padding: 0;
  list-style-type: none;
`;

const StatItem = styled.li`
  flex: 1;
  padding: ${({ theme }) => theme.space[1]};
  text-align: center;
  border: 0.25rem solid ${(props) => props.theme.colors.pink};
  border-radius: ${(props) => props.theme.radii[2]};
`;

const StatHeading = styled.h3`
  margin: 0 0 ${({ theme }) => theme.space[1]};
  font-size: ${(props) => props.theme.fontSizes[0]};
  font-weight: 400;
`;

const StatValue = styled.div`
  font-size: ${(props) => props.theme.fontSizes[1]};
  font-weight: 500;
`;

const ScrollTable = styled.div`
  overflow-x: auto;
  width: 100%;
  /* We're adding this in OverflowScrolling.css
	 * because the property gets removed from the
	 * styled component on test */
  /* -webkit-overflow-scrolling: touch; */
`;

const StyledCell = styled.td`
  &&& {
    padding: ${({ theme }) => theme.space[2]};
  }
  font-size: ${(props) => props.theme.fontSizes[0]};
  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    font-size: ${(props) => props.theme.fontSizes[1]};
  }
`;

const HistoryTable = styled(Table)`
  white-space: nowrap;
  > tbody > tr > td:nth-child(3) {
    font-weight: 500;
  }
  > tbody > tr > td:first-child {
    font-weight: 500;
  }
  > tbody > tr:last-child > td:nth-child(2) {
    font-weight: 500;
  }
  > tbody > tr:last-child > td:nth-child(3) {
    font-weight: 400;
  }
`;

interface IElementFixtureFPL extends IElementFixture {
  difficulty: number;
}

interface IOwnProps {
  element: IElement;
}

interface IPropsFromDispatch {
  addToWatched: (elementCode: number) => void;
  closeDialog: () => void;
  proposeElement: (elementId: number) => boolean;
  removeFromWatched: (elementCode: number) => void;
}

interface IPropsFromState {
  currentEvent: IEvent | null;
  elementTypesById: IElementTypesById;
  events: IEvent[];
  eventsById: IEventsById;
  fixtures: Array<IElementFixtureFPL | IElementFixtureBlank>;
  nextEvent: IEvent | null;
  now: IEvent | null;
  player: IPlayer | null;
  seasonHistory: IElementSeasonHistory[];
  stats: ReadonlyArray<IElementStat>;
  teamsById: ITeamsById;
  watched: number[];
  history: IElementHistory[];
  historyTotals: Record<string, number | string>;
}

type Props = IOwnProps & IPropsFromDispatch & IPropsFromState & WithTranslation;

class ElementDialog extends React.Component<Props> {
  public added = false;
  public componentWillUnmount() {
    this.props.closeDialog();
  }

  public addElement = (elementId: number) => {
    this.props.proposeElement(elementId);
    this.props.closeDialog();
  };

  public renderWatchlistButton() {
    const { addToWatched, element, player, removeFromWatched, t, watched } =
      this.props;
    if (!player || !player.entry) {
      return null;
    }
    const inWatched = watched.indexOf(element.code) > -1;
    if (inWatched) {
      return this.added ? (
        <p>
          {t(
            "elementDialog.watchlist.view",
            "View your watchlist on the transactions page"
          )}
        </p>
      ) : (
        <Button
          onClick={() => {
            removeFromWatched(element.code);
          }}
          fullwidth="true"
        >
          {t("elementDialog.watchlist.remove", "Remove from Watchlist")}
        </Button>
      );
    }
    return (
      <Button
        onClick={() => {
          this.added = true;
          addToWatched(element.code);
        }}
        fullwidth="true"
      >
        {t("elementDialog.watchlist.add", "Add to Watchlist")}
      </Button>
    );
  }

  public render() {
    const {
      closeDialog,
      currentEvent,
      element,
      elementTypesById,
      events,
      eventsById,
      fixtures,
      history,
      historyTotals,
      i18n,
      nextEvent,
      now,
      seasonHistory,
      stats,
      t,
      teamsById: teams,
    } = this.props;

    const getEventId = () => {
      if (currentEvent) {
        return currentEvent.id;
      }
      if (nextEvent) {
        return nextEvent.id;
      }
      return null;
    };

    const eventId = getEventId();
    const event = eventId ? eventsById[eventId] : null;
    // Element info
    const elementType = elementTypesById[element.element_type];
    const team = teams[element.team];
    const hasSeasonHistory = seasonHistory && seasonHistory.length > 0;

    // Get first 10 scheduled fixtures
    let count = 0;
    let filteredFixtures = [];
    for (const fixture of fixtures) {
      filteredFixtures.push(fixture);
      if (fixture.code) {
        count++;
      }
      if (count >= 10) {
        break;
      }
    }

    return (
      <Dialog closeDialog={closeDialog}>
        <ElementHeader>
          <ElementLogo>
            <StyledHeadlineLogo />
          </ElementLogo>
          <CloseButton onClick={closeDialog}>
            <VisuallyHidden>{t("elementDialog.close", "Close")}</VisuallyHidden>
            <CloseIcon />
          </CloseButton>
        </ElementHeader>
        <Dialog.Body>
          {element.news && (
            <Box m={2}>
              <Alert type="error">{element.news}</Alert>
            </Box>
          )}
          <Summary elementTypeId={element.element_type}>
            <SummaryInner teamCode={team.code}>
              <SummaryItem>
                <Headshot>
                  <Shirt elementId={element.id} />
                </Headshot>
              </SummaryItem>
              <SummaryItem>
                <SummaryInfo>
                  <SummaryName>
                    <div>{element.first_name}</div>
                    <div>{element.second_name}</div>
                  </SummaryName>
                  <SummaryType>{elementType.singular_name}</SummaryType>
                  <div>{team.name}</div>
                </SummaryInfo>
              </SummaryItem>
            </SummaryInner>
          </Summary>
          <StatList>
            <StatItem>
              <StatHeading>
                {event && (
                  <Tooltip content={event.name}>
                    <TooltipLabel $textDecoration={false}>
                      {getPrefixedEventShortName(event.name)}
                    </TooltipLabel>
                  </Tooltip>
                )}
              </StatHeading>
              <StatValue>
                {integerToDecimal(element.event_points, 10)}pts
              </StatValue>
            </StatItem>
            <StatItem>
              <StatHeading>
                {t("elementDialog.tabs.summary.total", "Total")}
              </StatHeading>
              <StatValue>
                {integerToDecimal(element.total_points, 10)}pts
              </StatValue>
            </StatItem>
            <StatItem>
              <StatHeading>
                {t("elementDialog.tabs.summary.pricen", "Salary")}
              </StatHeading>
              <StatValue>${integerToMoney(element.now_cost, 10)}</StatValue>
            </StatItem>
            <StatItem>
              <StatHeading>
                {t("elementDialog.tabs.summary.selectedBy", "Selected")}
              </StatHeading>
              <StatValue>{element.selected_by_percent}%</StatValue>
            </StatItem>
          </StatList>

          <Box my={4}>
            <CurrentGameweekSchedule element={element} />
          </Box>

          <Tabs centered={true} selected={!now ? 1 : 0}>
            <TabPanel
              label={t("elementDialog.tabs.history.heading", "History")}
              link="History"
            >
              {hasSeasonHistory && (
                <Box p={3}>
                  <SubHeading>
                    {t("elementDialog.tabs.history.subHeading", "This Season")}
                  </SubHeading>
                </Box>
              )}
              <Box>
                {history.length ? (
                  <ScrollTable className="ism-overflow-scroll">
                    <HistoryTable>
                      <thead>
                        <tr>
                          <th>
                            {t("elementDialog.tabs.history.roundShort", "GD")}
                          </th>
                          <th>
                            {t(
                              "elementDialog.tabs.history.opponentShort",
                              "OPP"
                            )}
                          </th>
                          <th>
                            {t("elementDialog.tabs.history.pointsShort", "PTS")}
                          </th>
                          {stats.map((s) => (
                            <th key={s.name}>
                              <TooltipLabel
                                $textDecoration={false}
                                title={s.label}
                              >
                                {s.label
                                  .split(" ")
                                  .map((w) => w[0])
                                  .join("")
                                  .toUpperCase()}
                              </TooltipLabel>
                            </th>
                          ))}
                          <th>
                            {t(
                              "elementDialog.tabs.history.netTransfersShort",
                              "NT"
                            )}
                          </th>
                          <th>
                            {t(
                              "elementDialog.tabs.history.selectedByShort",
                              "SB"
                            )}
                          </th>
                          <th>$</th>
                        </tr>
                      </thead>
                      <tbody>
                        {[...history].reverse().map((h) => (
                          <tr key={h.fixture}>
                            <StyledCell>
                              {eventsById[h.round] && (
                                <Tooltip content={eventsById[h.round].name}>
                                  <TooltipLabel $textDecoration={false}>
                                    {getPrefixedEventShortName(
                                      eventsById[h.round].name
                                    )}
                                  </TooltipLabel>
                                </Tooltip>
                              )}
                            </StyledCell>
                            <StyledCell>
                              <Badge
                                teamId={teams[h.opponent_team].id}
                                width="23px"
                              />
                              {h.was_home
                                ? t(
                                    "elementDialog.tabs.history.oppHome",
                                    "vs.{{ shortName }}",
                                    {
                                      shortName:
                                        teams[h.opponent_team].short_name,
                                    }
                                  )
                                : t(
                                    "elementDialog.tabs.history.oppAway",
                                    "@{{ shortName }}",
                                    {
                                      shortName:
                                        teams[h.opponent_team].short_name,
                                    }
                                  )}{" "}
                              {h.team_h_score !== null &&
                                `${h.team_h_score} - ${h.team_a_score}`}
                            </StyledCell>
                            <StyledCell>
                              {integerToDecimal(h.total_points, 10)}
                            </StyledCell>
                            {stats.map((s) => (
                              <StyledCell key={s.name}>{h[s.name]}</StyledCell>
                            ))}
                            <StyledCell>{h.transfers_balance}</StyledCell>
                            <StyledCell>{h.selected}</StyledCell>
                            <StyledCell>
                              ${integerToMoney(h.value, 10)}
                            </StyledCell>
                          </tr>
                        ))}
                        <tr>
                          <StyledCell colSpan={2}>
                            {t("elementDialog.tabs.history.totals", "Totals")}
                          </StyledCell>
                          <StyledCell>
                            {integerToDecimal(
                              historyTotals.total_points as number,
                              10
                            )}
                          </StyledCell>
                          {stats.map((s) => (
                            <StyledCell key={s.name}>
                              {historyTotals[s.name]}
                            </StyledCell>
                          ))}
                          <StyledCell>&nbsp;</StyledCell>
                          <StyledCell>&nbsp;</StyledCell>
                          <StyledCell>&nbsp;</StyledCell>
                        </tr>
                      </tbody>
                    </HistoryTable>
                  </ScrollTable>
                ) : (
                  <Box px={3}>
                    <p>
                      {t(
                        "elementDialog.tabs.history.noData",
                        "Data will appear here once the season is underway."
                      )}
                    </p>
                  </Box>
                )}
              </Box>

              {hasSeasonHistory && (
                <>
                  <Box p={3}>
                    <SubHeading>
                      {t(
                        "elementDialog.tabs.history.previousSeasons",
                        "Previous Seasons"
                      )}
                    </SubHeading>
                  </Box>
                  <Box>
                    <ScrollTable>
                      <HistoryTable>
                        <thead>
                          <tr>
                            <th>
                              {t("elementDialog.tabs.history.season", "Season")}
                            </th>
                            <th>
                              <Tooltip
                                content={t(
                                  "elementDialog.tabs.history.points",
                                  "Points"
                                )}
                              >
                                <TooltipLabel>TP</TooltipLabel>
                              </Tooltip>
                            </th>
                            {stats.map((s) => (
                              <th key={s.name}>
                                <Tooltip content={s.label}>
                                  <TooltipLabel>
                                    {s.label
                                      .split(" ")
                                      .map((w) => w[0])
                                      .join("")
                                      .toUpperCase()}
                                  </TooltipLabel>
                                </Tooltip>
                              </th>
                            ))}
                            <th>
                              <abbr
                                title={t(
                                  "elementDialog.tabs.history.priceAtStartn",
                                  "Salary at start of season"
                                )}
                              >
                                krS
                              </abbr>
                            </th>
                            <th>
                              <abbr
                                title={t(
                                  "elementDialog.tabs.history.priceAtEnd",
                                  "Salary at end of season"
                                )}
                              >
                                krE
                              </abbr>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {seasonHistory.map((h) => (
                            <tr key={h.season_name}>
                              <StyledCell>{h.season_name}</StyledCell>
                              <StyledCell>
                                {integerToDecimal(h.total_points, 10)}
                              </StyledCell>
                              {stats.map((s) => (
                                <StyledCell key={s.name}>
                                  {h[s.name]}
                                </StyledCell>
                              ))}
                              <StyledCell>
                                {integerToMoney(h.start_cost, 10)}
                              </StyledCell>
                              <StyledCell>
                                {integerToMoney(h.end_cost, 10)}
                              </StyledCell>
                            </tr>
                          ))}
                        </tbody>
                      </HistoryTable>
                    </ScrollTable>
                  </Box>
                </>
              )}
            </TabPanel>

            <TabPanel
              label={t("elementDialog.tabs.schedule.heading", "Schedule")}
              link="Schedule"
            >
              <Box mt={2}>
                <Table>
                  <thead>
                    <tr>
                      <th>
                        {t("elementDialog.tabs.schedule.date", "Game date")}
                      </th>
                      <th>
                        {t("elementDialog.tabs.schedule.roundShort", "GD")}
                      </th>
                      <th>
                        {t("elementDialog.tabs.schedule.opponent", "Opponent")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredFixtures.map((fixture) =>
                      fixture.code ? (
                        <tr key={fixture.code}>
                          <StyledCell>
                            {fixture.kickoff_time ? (
                              <time dateTime={fixture.kickoff_time}>
                                {formatRawAsLocalI18n(
                                  fixture.kickoff_time,
                                  dateLocales[i18n.language]
                                )}
                              </time>
                            ) : (
                              <Tooltip
                                content={t(
                                  "elementDialog.tabs.schedule.tbcLong",
                                  "Date To Be Confirmed"
                                )}
                              >
                                <span>
                                  <TooltipLabel>
                                    {t(
                                      "elementDialog.tabs.schedule.tbcShort",
                                      "TBC"
                                    )}
                                  </TooltipLabel>
                                </span>
                              </Tooltip>
                            )}
                          </StyledCell>
                          <StyledCell>{fixture.event_name}</StyledCell>
                          <StyledCell>
                            <FixtureForElement
                              showBadge={true}
                              element={element}
                              fixture={fixture}
                              teamsById={teams}
                            />
                          </StyledCell>
                        </tr>
                      ) : (
                        <tr key={fixture.event!}>
                          <StyledCell>&nbsp;</StyledCell>
                          <StyledCell>
                            {events.map((event) => {
                              if (event.id === fixture.event) {
                                return event.name;
                              } else {
                                return null;
                              }
                            })}
                          </StyledCell>
                          <StyledCell>{"-"}</StyledCell>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              </Box>
            </TabPanel>
          </Tabs>
          <Box mx={2} mt={3}>
            {this.renderWatchlistButton()}
          </Box>
        </Dialog.Body>
      </Dialog>
    );
  }
}

export { ElementDialog as ElementDialogTest };

const mapStateToProps = (
  state: RootState,
  ownProps: IOwnProps
): IPropsFromState => ({
  currentEvent: getCurrentEvent(state),
  elementTypesById: getElementTypesById(state),
  events: getEvents(state),
  eventsById: getEventsById(state),
  fixtures: getFixturesWithBlanks(state, ownProps.element.id) as Array<
    IElementFixtureFPL | IElementFixtureBlank
  >,
  history: getHistory(state, ownProps.element.id),
  historyTotals: getHistoryTotals(state, ownProps.element.id),
  nextEvent: getNextEvent(state),
  now: getCurrentEvent(state),
  player: getPlayerData(state),
  seasonHistory: getSeasonHistory(state, ownProps.element.id).slice().reverse(),
  stats: getElementStats(state),
  teamsById: getTeamsById(state),
  watched: getWatched(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  addToWatched: (elementCode) => dispatch(addToWatchlist(elementCode)),
  closeDialog: () => dispatch(elementDialogHide()),
  proposeElement: (elementId) => dispatch(proposeElement(elementId)),
  removeFromWatched: (elementCode) =>
    dispatch(removeFromWatchlist(elementCode)),
});

const ConnectedDialog = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ElementDialog)
);

/* tslint:disable:max-classes-per-file */
interface IContainerProps {
  element: IElement | undefined;
}

class ElementDialogContainer extends React.Component<IContainerProps> {
  public render() {
    const element = this.props.element;
    return element ? <ConnectedDialog element={element} /> : null;
  }
}

export default connect((state: RootState): IContainerProps => {
  const elementId = getElementDialog(state);
  return {
    element: getElement(state, elementId),
  };
})(ElementDialogContainer);
